import React from "react";
import { Dropdown, message } from "antd";

const DevicePopUp = ({ Count, data }) => {
  const items = data?.map((item) => {
    return { id: item.id, label: item.name };
  });
  const handleMenuClick = (e) => {};

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <Dropdown
      menu={menuProps}
      placement="bottomLeft"
      arrow={{
        pointAtCenter: true,
      }}
    >
      <span style={{ cursor: "pointer" }}>{Count}</span>
    </Dropdown>
  );
};

export default DevicePopUp;

import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import authSliceReducer from "./Slices/AuthSlice";
import CurrentPlan from "./Slices/CurrentPlan";
import SelectSlice from "./Slices/SelectSlice";
import UserSliceReducer from "./Slices/UserSlice";

const store = configureStore({
  reducer: {
    AuthUser: authSliceReducer,
    Select: SelectSlice,
    user: UserSliceReducer,
    userPlan: CurrentPlan,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export default store;

import moment from "moment";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { BsInbox } from "react-icons/bs";
import { IoArrowBack } from "react-icons/io5";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getUsersAllSubscriptions,
  getUsersSubscriptions,
} from "../../../ApisURl/ApisUrl";

const SubscriptionHistory = () => {
  const { farmer, subscription } = useSelector(
    (state) => state.userPlan.userCurrentPackage
  );
  const navigate = useNavigate();

  const [userSubscriptions, setUserSubscriptions] = useState([]);

  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + 10;

  const currentItems = userSubscriptions?.slice(itemOffset, endOffset) || [];

  const pageCount = Math.ceil(userSubscriptions?.length / 10);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * 10) % userSubscriptions?.length;

    setItemOffset(newOffset);
  };

  useEffect(() => {
    if (farmer?.id) {
      getUsersAllSubscriptions(farmer?.id).then((res) => {
        if (res) {
          const reversedData = res.response.reverse();
          setUserSubscriptions(reversedData);
        }
      });
    }
  }, []);

  return (
    <div className="subscription-histroy">
      <div className="setting-section-header d-flex justify-content-between align-items-center">
        <h1 className="title mt-2 d-flex align-items-center ">
          <span
            className="go-back  me-2"
            title=" Back "
            onClick={() => navigate("/Farmers")}
          >
            <IoArrowBack />
          </span>
          {farmer?.first_name}{" "}
          <span className="mx-2 fw-small">Subscription History</span>
        </h1>
      </div>
      <div className="bg-white   mt-3">
        <div className="tables-header px-4  py-4 ">
          <p className="fw-4">All Subscriptions</p>
          <table className="table " style={{ borderTop: "none !important" }}>
            <thead>
              <tr>
                <th>Sr No</th>
                <th>Title </th>
                <th>Description</th> <th>Price</th>
                <th>Subscription Type</th>
                <th> Start Date</th>
                <th> End Date</th>
                <th> Subscribed Date</th>
              </tr>
            </thead>
            <tbody>
              {currentItems &&
                currentItems?.map((ele, index) => {
                  return (
                    <tr
                      key={ele.id}
                      // style={
                      //   title === userRecord?.subscription?.title
                      //     ? { background: "#ecf2f4" }
                      //     : {}
                      // }
                    >
                      <td>{index + 1}</td>
                      <td>{ele.package.title}</td>
                      <td>
                        <div
                          title={ele.package.description}
                          style={{
                            width: "120px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {ele.package.description}
                        </div>
                      </td>
                      <td>{ele?.package?.price}</td>
                      <td>{ele?.package?.subscription_type}</td>
                      <td>{moment(ele?.start_date).format("YYYY-MM-DD")}</td>
                      <td>{moment(ele?.end_date).format("YYYY-MM-DD")}</td>
                      <td>{moment(ele?.createdAt).format("YYYY-MM-DD")}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>

        {currentItems?.length === 0 && (
          <div className="d-flex flex-column justify-content-center align-items-center">
            <div style={{ fontSize: "50px", color: "#a9b6c2" }}>
              <BsInbox />
            </div>
            <p style={{ color: "#a9b6c2" }}>No Record Found.</p>
          </div>
        )}
        <div className="paginate s-admin">
          {currentItems?.length !== 0 && userSubscriptions?.length >= 10 && (
            <ReactPaginate
              breakLabel="..."
              nextLabel={">"}
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel={"<"}
              renderOnZeroPageCount={null}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionHistory;

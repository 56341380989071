import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { FiUsers } from "react-icons/fi";
import { RiCustomerService2Fill } from "react-icons/ri";
import { getAllSensor } from "../../../ApisURl/ApisUrl";
import Serchbar from "../../../Component/Serchbar/Serchbar";
import AllEnquiry from "./AllEnquiry";
import AllFarmers from "./AllFarmers";

const IndexFarmers = () => {
  const [tab, setTab] = useState("0");
  const [allFarmers, setAllFarmers] = useState([]);
  const [pageChnages, setPageChnage] = useState(false);
  const [filterRows, setFilterRows] = useState({
    filterRow: [],
    serchText: "",
  });

  function performSearch(regex) {
    if (tab === "0") {
      return allFarmers?.farmers?.filter((item) => {
        return (
          (item?.farmer?.first_name.match(regex) ||
            item?.farmer?.email.match(regex)) &&
          item?.farmer?.enquiry_flag === true
        );
      });
    } else if (tab === "1") {
      return allFarmers?.farmers?.filter((item) => {
        return (
          (item?.farmer?.first_name.match(regex) ||
            item?.farmer?.email.match(regex)) &&
          item?.farmer?.enquiry_flag === false
        );
      });
    }
  }

  function handleSearch(newSearchTerm) {
    const regex = new RegExp(newSearchTerm, "i");
    const result = performSearch(regex);
    setFilterRows({
      ...filterRows,
      filterRow: result,
      serchText: newSearchTerm,
    });
  }
  return (
    <div className="setting-section">
      <div className="setting-section-header d-flex justify-content-between align-items-center">
        <h1 className="title mt-2 d-flex flex-column" style={{ gap: "15px" }}>
          Farmers
          {/* <span className=" sub-title ">Product Management</span> */}
        </h1>
        <Serchbar onSearch={handleSearch} tab={tab} />
      </div>

      <div className="setting-body-section position-relative">
        <div className="setting-table">
          <Tabs
            defaultActiveKey={tab}
            id="uncontrolled-tab-example"
            className="mb-3"
            onSelect={(k) => {
              setTab(k);
              setFilterRows({
                filterRow: [],
                serchText: "",
              });
            }}
          >
            <Tab
              eventKey="0"
              title={
                <span>
                  {" "}
                  <span className="table-icons">
                    <FiUsers />{" "}
                  </span>{" "}
                  Farmers{" "}
                </span>
              }
            >
              <AllFarmers
                farmer={allFarmers}
                setfarmer={setAllFarmers}
                filterRows={filterRows}
                pageChnages={pageChnages}
                setPageChnage={setPageChnage}
              />
            </Tab>
            <Tab
              eventKey="1"
              title={
                <span>
                  <span className="table-icons">
                    <RiCustomerService2Fill />{" "}
                  </span>{" "}
                  Enquiry
                </span>
              }
            >
              <AllEnquiry
                farmer={allFarmers}
                setfarmer={setAllFarmers}
                filterRows={filterRows}
                pageChnages={pageChnages}
                setPageChnage={setPageChnage}
              />
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default IndexFarmers;

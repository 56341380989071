import { Modal } from "antd";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import { useState } from "react";
import { BiPencil } from "react-icons/bi";
import { editProfile, registerFarmer } from "../../../../ApisURl/ApisUrl";
import Button from "../../../../Component/Button";
const CreateFarmer = ({ isModalOpen, setIsModalOpen, editRecord }) => {
  const [editable, setEditable] = useState(false);
  const getValues = (value) => {
    if (editRecord) {
      let data = {
        ...value,
        userId: editRecord?.id,
      };
      delete data.password;
      delete data.email;

    

      editProfile(data).then((res) => {
        if (res) {
          setIsModalOpen(false);
          setEditable(false);
        }
      });
    } else {
      registerFarmer(value).then((res) => {
        if (res.status === 200) {
          setIsModalOpen(false);
          setEditable(false);
        }
      });
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    // setEditable(false);
  };
  return (
    <Modal
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      destroyOnClose
      closable={false}
      footer={false}
      width={650}
      className=""
    >
      <Formik
        initialValues={{
          first_name: editRecord ? editRecord.first_name : "",
          last_name: editRecord ? editRecord.last_name : "",
          contact_No: editRecord ? editRecord.contact_No : "",
          company_name: editRecord ? editRecord.company_name : "",
          email: editRecord ? editRecord.email : "",
          password: editRecord ? editRecord.password : "",
        }}
        validate={(values) => {
          const errors = {};
          if (!values.first_name) {
            errors.first_name = "Required";
          }

          if (!values.last_name) {
            errors.last_name = "Required";
          }
          if (values.first_name.length > 50) {
            errors.first_name = "First name must be 50 characters or less.";
          }

          if (values.last_name.length > 50) {
            errors.last_name = "Last name must be 50 characters or less.";
          }
          if (!values.email) {
            errors.email = "Required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }

          if (!values.company_name) {
            errors.company_name = "Required";
          } else if (values.company_name?.length > 200) {
            errors.company_name =
              "Company name must be 200 characters or less.";
          }

          if (!values.contact_No) {
            errors.contact_No = "Mobile number is required";
          } else if (!/^\d+$/.test(values.contact_No)) {
            errors.contact_No = "Mobile number must contain only numbers";
          } else if (values.contact_No.toString().length !== 10) {
            errors.contact_No = "Mobile number must be exactly 10 digits";
          }

          if (!values.password) {
            errors.password = "Required";
          }
          return errors;
        }}
        onSubmit={(values, { resetForm }) => {
          getValues(values, resetForm);
        }}
        className="group-create-modal"
      >
        {({ values, touched, errors }) => (
          <Form>
            <div className="form_wraper h-wraper-65">
              <div
                className="position-relative"
                style={{
                  borderBottom: "1px solid rgba(0,0 ,0 ,0.1)",
                  padding: "0 10px 20px 10px",
                }}
              >
                <div className="d-flex ">
                  <label
                    htmlFor="last_name"
                    className="d-flex align-items-center justify-space-between "
                  >
                    {" "}
                    <span
                      style={{
                        fontSize: "14px",
                        color: "#00000",
                        fontWeight: "600",
                      }}
                    >
                      First Name
                    </span>
                    <Field
                      type="text"
                      name="first_name"
                      autoComplete="off"
                      placeholder="First Name"
                      className={`form-control FormikInput ms-1 ${
                        touched.first_name && errors.first_name
                          ? "invalid-field"
                          : ""
                      }`}
                      style={{ width: "70%" }}
                      disabled={editRecord && !editable}
                    />
                  </label>
                  <label
                    htmlFor="last_name"
                    className="d-flex align-items-center justify-space-between ms-2"
                  >
                    {" "}
                    <span
                      style={{
                        fontSize: "14px",
                        color: "#00000",
                        fontWeight: "600",
                      }}
                    >
                      Last Name &nbsp;
                    </span>
                    <Field
                      type="text"
                      name="last_name"
                      autoComplete="off"
                      placeholder="Last Name"
                      className={`form-control FormikInput ${
                        touched.last_name && errors.last_name
                          ? "invalid-field"
                          : ""
                      }`}
                      style={{ width: "70%" }}
                      disabled={editRecord && !editable}
                    />
                  </label>
                </div>
                <ErrorMessage
                  name="first_name"
                  component="div"
                  className="Errormessage"
                  style={{ marginLeft: "87px" }}
                />
                <ErrorMessage
                  name="last_name"
                  component="div"
                  className="Errormessage "
                  style={{
                    marginLeft: "57px",
                    top: "49px",
                    position: "absolute",
                    left: "330px",
                  }}
                />
              </div>

              <div
                className="pt-3 position-relative"
                style={{
                  borderBottom: "1px solid rgba(0,0 ,0 ,0.1)",
                  padding: "0 10px 20px 10px",
                }}
              >
                <div className="d-flex">
                  <label
                    htmlFor="Name"
                    className="d-flex align-items-center justify-space-between"
                  >
                    {" "}
                    <span
                      className="d-flex align-items-center "
                      style={{
                        fontSize: "14px",
                        color: "#00000",
                        fontWeight: "600",
                      }}
                    >
                      Email &nbsp;
                    </span>
                    <Field
                      type="text"
                      name="email"
                      autoComplete="off"
                      placeholder="Email"
                      style={{ width: "80%" }}
                      className={`form-control FormikInput ${
                        touched.email && errors.email ? "invalid-field" : ""
                      }`}
                      disabled={editRecord && !editable}
                    />
                  </label>
                  {!editRecord && (
                    <label
                      htmlFor="Name"
                      className="d-flex align-items-center justify-space-between ms-3"
                    >
                      <span
                        style={{
                          fontSize: "14px",
                          color: "#00000",
                          fontWeight: "600",
                        }}
                      >
                        Password &nbsp;
                      </span>
                      <div>
                        <Field
                          type="text"
                          name="password"
                          autoComplete="off"
                          placeholder="Password"
                          className={`form-control FormikInput ${
                            touched.password && errors.password
                              ? "invalid-field"
                              : ""
                          }`}
                          style={{ width: "80%" }}
                          disabled={editRecord && !editable}
                        />
                      </div>
                    </label>
                  )}
                </div>
                <ErrorMessage
                  name="email"
                  component="div"
                  className="Errormessage"
                  style={{ marginLeft: "57px" }}
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="Errormessage position-absolute"
                  style={{
                    marginLeft: "57px",
                    top: "65px",
                    position: "absolute",
                    left: "294px",
                  }}
                />
              </div>

              <div
                style={{
                  borderBottom: "1px solid rgba(0,0 ,0 ,0.1)",
                  padding: "20px 10px 20px 10px",
                }}
              >
                <label
                  htmlFor="contact_No"
                  className="d-flex align-items-center justify-space-between"
                >
                  <span
                    style={{
                      fontSize: "14px",
                      color: "#00000",
                      fontWeight: "600",
                    }}
                  >
                    Mobile Number &nbsp;
                  </span>
                  <Field
                    type="text"
                    name="contact_No"
                    autoComplete="off"
                    placeholder="Enter Contact Number"
                    className={`form-control FormikInput ${
                      touched.contact_No && errors.contact_No
                        ? "invalid-field"
                        : ""
                    }`}
                    style={{ width: "40%" }}
                    disabled={editRecord && !editable} // onPaste={(event) => handlePaste(event, setFieldValue)}
                    // onChange={(event) => handlePaste(event, setFieldValue)}
                  />
                </label>
                <ErrorMessage
                  name="contact_No"
                  component="div"
                  className="Errormessage"
                  style={{ marginLeft: "125px" }}
                />
              </div>
              <div
                style={{
                  borderBottom: "1px solid rgba(0,0 ,0 ,0.1)",
                  padding: "20px 10px 20px 10px",
                }}
              >
                <label
                  htmlFor="company_name"
                  className="d-flex align-items-center justify-space-between"
                >
                  <span
                    style={{
                      fontSize: "14px",
                      color: "#00000",
                      fontWeight: "600",
                    }}
                  >
                    Company Name &nbsp;
                  </span>
                  <Field
                    type="text"
                    name="company_name"
                    autoComplete="off"
                    placeholder="Enter Company Name"
                    className={`form-control FormikInput ${
                      touched.company_name && errors.company_name
                        ? "invalid-field"
                        : ""
                    }`}
                    style={{ width: "40%" }}
                    disabled={editRecord && !editable}
                    // onPaste={(event) => handlePaste(event, setFieldValue)}
                    // onChange={(event) => handlePaste(event, setFieldValue)}
                  />
                </label>
                <ErrorMessage
                  name="company_name"
                  component="div"
                  className="Errormessage"
                  style={{ marginLeft: "125px" }}
                />
              </div>

              {editRecord ? (
                <div className="form-modal-btn text-end pt-3 pb-2">
                  {editable && editRecord ? (
                    <>
                      <Button
                        text="Cancel"
                        buttonType="button"
                        buttonStyle="global-btn secondary-btn me-3 px-3"
                        oK={() => {
                          setIsModalOpen(false);
                          setEditable(false);
                        }}
                      />
                      <Button
                        text="Update"
                        buttonType="Submit"
                        buttonStyle="global-btn primary-btn px-3"
                      />
                    </>
                  ) : (
                    <Button
                      text="Edit"
                      buttonType="button"
                      icon={<BiPencil className="me-1" />}
                      buttonStyle="global-btn primary-btn me-3 px-4"
                      oK={() => {
                        setEditable(true);
                      }}
                    />
                  )}
                </div>
              ) : (
                <div className="form-modal-btn text-end py-3">
                  <Button
                    text="Cancel"
                    buttonStyle="global-btn secondary-btn me-3 px-3"
                    oK={() => {
                      setIsModalOpen(false);
                      setEditable(false);
                    }}
                  />
                  <Button
                    text="Create"
                    buttonType={"Submit"}
                    buttonStyle={"global-btn primary-btn  px-4"}
                  />
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default CreateFarmer;

import { Modal, Select } from "antd";
import {
  ErrorMessage,
  Field,
  Form,
  Formik,
  useField,
  useFormikContext,
} from "formik";
import React, { useEffect } from "react";
import { useState } from "react";
import { BiPencil } from "react-icons/bi";
import { createPackage, editPackage } from "../../../../ApisURl/ApisUrl";
import Button from "../../../../Component/Button";

const MyFieldOk = (props) => {
  const { values, touched, setFieldValue } = useFormikContext();

  const [field, meta] = useField(props);

  useEffect(() => {
    if (touched.discount || touched.original_price) {
      let percentage = values.original_price * (values.discount / 100);

      setFieldValue(props.name, values.original_price - percentage);
    }
  }, [
    touched.discount,
    touched.original_price,
    setFieldValue,
    props.name,
    values.discount,
  ]);

  return (
    <>
      <input {...props} {...field} />
      {/* {!!meta.touched && !!meta.error && <div>{meta.error}</div>} */}
    </>
  );
};
const CreatPackages = ({ isModalOpen, setIsModalOpen, editDevicesItem }) => {
  const [editable, setEditable] = useState(false);
  const getValues = (value) => {
    if (editDevicesItem) {
      editPackage({ id: editDevicesItem.id, body: value }).then((res) => {
        if (res) {
          setIsModalOpen(false);
        }
      });
    } else {
      createPackage(value).then((res) => {
        if (res) {
          setIsModalOpen(false);
        }
      });
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setEditable(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setEditable(false);
  };
  return (
    <Modal
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      destroyOnClose
      closable={false}
      footer={false}
      width={650}
      className=""
    >
      <Formik
        initialValues={{
          title: editDevicesItem ? editDevicesItem.title : "",
          description: editDevicesItem ? editDevicesItem.description : "",
          original_price: editDevicesItem ? editDevicesItem.original_price : "",
          discount: editDevicesItem ? editDevicesItem.discount : "",
          price: editDevicesItem ? editDevicesItem.price : "",
          subscription_type: editDevicesItem
            ? editDevicesItem.subscription_type
            : "Free",
        }}
        validate={(values) => {
          const errors = {};
          if (!values.title) {
            errors.title = "Required";
          } else if (values.title.length > 50) {
            errors.title =
              "Title is required and should be below 50 characters";
          }

          if (!values.description) {
            errors.description = "Required";
          } else if (values.description.length > 200) {
            errors.description =
              "Description is required and should be below 200 characters";
          }

          if (!values.original_price) {
            errors.original_price = "Required";
          }
          if (!values.discount) {
            errors.discount = "Required";
          }
          if (!values.price) {
            errors.price = "Required";
          }

          if (values.original_price < 0) {
            errors.original_price = "Original price must be greater  zero";
          }

          if (values.discount < 0) {
            errors.discount = "Discount must be greater  zero";
          }

          if (values.price < 0) {
            errors.price = "Price must be greater  zero";
          }

          if (!values.subscription_type) {
            errors.subscription_type = "Required";
          }
          return errors;
        }}
        onSubmit={(values, { resetForm }) => {
          getValues(values, resetForm);
        }}
        className="group-create-modal"
      >
        {({ values, setFieldValue, touched, errors }) => (
          <Form>
            <div className="form_wraper h-wraper-65">
              <div
                style={{
                  borderBottom: "1px solid rgba(0,0 ,0 ,0.1)",
                  padding: "0 10px 20px 10px",
                }}
              >
                <div className="">
                  <label
                    htmlFor="title"
                    className="d-flex align-items-center justify-space-between"
                  >
                    {" "}
                    <span
                      style={{
                        fontSize: "14px",
                        color: "#00000",
                        fontWeight: "600",
                      }}
                    >
                      Title :
                    </span>
                  </label>
                  <Field
                    type="text"
                    name="title"
                    autoComplete="off"
                    placeholder="Title "
                    className={`form-control FormikInput ${touched.title && errors.title ? "invalid-field" : ""
                      }`}
                    style={{ width: "48%" }}
                    disabled={editDevicesItem && !editable}
                  />
                </div>
                <ErrorMessage
                  name="title"
                  component="div"
                  className="Errormessage"
                />
              </div>
              <div
                style={{
                  borderBottom: "1px solid rgba(0,0 ,0 ,0.1)",
                  padding: "20px 10px 20px 10px",
                }}
              >
                <label
                  htmlFor="last_name"
                  className="d-flex align-items-center justify-space-between ms-2"
                >
                  {" "}
                  <span
                    style={{
                      fontSize: "14px",
                      color: "#00000",
                      fontWeight: "600",
                    }}
                  >
                    Description :
                  </span>
                </label>
                <Field
                  as="textarea"
                  row={14}
                  name="description"
                  autoComplete="off"
                  placeholder="write here..."
                  className={`form-control FormikInput ${touched.description && errors.description
                    ? "invalid-field"
                    : ""
                    }`}
                  disabled={editDevicesItem && !editable}
                />
                <ErrorMessage
                  name="description"
                  className="Errormessage"
                  component="div"
                />
              </div>

              <div
                style={{
                  borderBottom: "1px solid rgba(0,0 ,0 ,0.1)",
                  padding: "20px 10px 20px 10px",
                }}
              >
                <div className="d-flex">
                  <div className="w-100 me-3">
                    <label
                      htmlFor="contact_No"
                      className="d-flex align-items-center justify-space-between"
                    >
                      <span
                        style={{
                          fontSize: "14px",
                          color: "#00000",
                          fontWeight: "600",
                        }}
                      >
                        Package price :
                      </span>
                    </label>
                    <Field
                      type="number"
                      name="original_price"
                      autoComplete="off"
                      placeholder="Enter here price"
                      className={`form-control FormikInput ${touched.original_price && errors.original_price
                        ? "invalid-field"
                        : ""
                        }`}
                      style={{ width: "100%" }}
                      disabled={editDevicesItem && !editable}
                    // onPaste={(event) => handlePaste(event, setFieldValue)}
                    // onChange={(event) => handlePaste(event, setFieldValue)}
                    />
                    <ErrorMessage
                      name="original_price"
                      component="div"
                      className="Errormessage"
                    // style={{ marginLeft: "107px" }}
                    />
                  </div>
                  <div className="w-100 ">
                    <label
                      htmlFor="company_name"
                      className="d-flex align-items-center justify-space-between"
                    >
                      <span
                        style={{
                          fontSize: "14px",
                          color: "#00000",
                          fontWeight: "600",
                        }}
                      >
                        Discount percentage :
                      </span>
                    </label>
                    <Field
                      type="number"
                      name="discount"
                      autoComplete="off"
                      placeholder="Enter percentage count"
                      className={`form-control FormikInput ${touched.discount && errors.discount
                        ? "invalid-field"
                        : ""
                        }`}
                      style={{ width: "100%" }}
                      disabled={editDevicesItem && !editable}
                    // onPaste={(event) => handlePaste(event, setFieldValue)}
                    // onChange={(event) => handlePaste(event, setFieldValue)}
                    />
                    <ErrorMessage
                      name="discount"
                      component="div"
                      className="Errormessage"
                    // style={{ marginLeft: "107px" }}
                    />
                  </div>
                </div>
              </div>

              <div
                style={{
                  borderBottom: "1px solid rgba(0,0 ,0 ,0.1)",
                  padding: "20px 10px 20px 10px",
                }}
              >
                <div className="d-flex">
                  <div className="w-100 me-3">
                    <label
                      htmlFor="contact_No"
                      className="d-flex align-items-center justify-space-between"
                    >
                      <span
                        style={{
                          fontSize: "14px",
                          color: "#00000",
                          fontWeight: "600",
                        }}
                      >
                        Discounted price :
                      </span>
                    </label>
                    <MyFieldOk
                      type="number"
                      name="price"
                      readOnly
                      autoComplete="off"
                      placeholder="Discounted price"
                      className={`form-control FormikInput ${touched.price && errors.price ? "invalid-field" : ""
                        }`}
                      style={{ width: "100%" }}
                      disabled={editDevicesItem && !editable}
                    />
                    <ErrorMessage
                      name="price"
                      component="div"
                      className="Errormessage"
                    />
                  </div>
                  <div className="w-100 ">
                    <label
                      htmlFor="company_name"
                      className="d-flex align-items-center justify-space-between"
                    >
                      <span
                        style={{
                          fontSize: "14px",
                          color: "#00000",
                          fontWeight: "600",
                        }}
                      >
                        Type :
                      </span>
                    </label>

                    <Select
                      id="subscription_type"
                      name="subscription_type"
                      className={`w-100 FormikInput ${touched.subscription_type && errors.subscription_type
                        ? "invalid-field"
                        : ""
                        }`}
                      placeholder="Select group"
                      disabled={editDevicesItem && !editable}
                      style={{ color: "red", width: "100%" }}
                      // disabled={!edit}
                      onChange={(value) => {
                        setFieldValue("subscription_type", value);
                      }}
                      defaultValue={values.subscription_type}
                    >
                      <option value={"Free"}>Free</option>
                      <option value={"Monthly"}>Monthly</option>
                      <option value={"Halfyearly"}>Half year</option>
                      <option value={"Yearly"}>Year</option>
                    </Select>
                  </div>
                </div>
              </div>

              {editDevicesItem ? (
                <div className="form-modal-btn text-end pt-3 pb-2">
                  {editable && editDevicesItem ? (
                    <>
                      <Button
                        text="Cancel"
                        buttonType="button"
                        buttonStyle="global-btn secondary-btn me-3 px-3"
                        oK={() => {
                          setEditable(false);
                        }}
                      />
                      <Button
                        text="Update"
                        buttonType="Submit"
                        buttonStyle="global-btn primary-btn px-3"
                      />
                    </>
                  ) : (
                    <Button
                      text="Edit"
                      buttonType="button"
                      icon={<BiPencil className="me-1" />}
                      buttonStyle="global-btn primary-btn me-3 px-4"
                      oK={() => {
                        setEditable(true);
                      }}
                    />
                  )}
                </div>
              ) : (
                <div className="form-modal-btn text-end py-3">
                  <Button
                    text="Cancel"
                    buttonStyle="global-btn secondary-btn me-3 px-3"
                    oK={() => setIsModalOpen(false)}
                  />
                  <Button
                    text="Create"
                    buttonType={"Submit"}
                    buttonStyle={"global-btn primary-btn  px-4"}
                  />
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default CreatPackages;

import React from "react";
import { Link } from "react-router-dom";
import PageNotFoundImg from ".././Assets/Images/PageNot.svg";

const PageNotFound = () => {
  return (
    <div
      className="Page-not-found-section position-relative d-flex flex-column"
      style={{ width: "100%", height: "100vh" }}
    >
      <img
        src={PageNotFoundImg}
        alt=""
        style={{ width: "100%", height: "100%" }}
      />

      <button
        style={{
          position: "absolute",
          bottom: "2%",
          left: "45%",
          padding: "10px 30px",
          borderRadius: "10px",
          background: "#88B0B9",
          border: "None",
          fontWeight: "700",
        }}
      >
        <Link to="/" style={{ color: "white" }}>
          Go Back
        </Link>
      </button>
    </div>
  );
};

export default PageNotFound;

import { Modal } from "antd";
import { Formik, Form, Field, useFormikContext, useField } from "formik";
import { useEffect, useState } from "react";
import { BiPencil } from "react-icons/bi";
import {
  getAllSensorsReadings,
  getEditEndRegisterdDevice,
  registerEndDevices,
} from "../../../ApisURl/ApisUrl";
import Button from "../../../Component/Button";
import createEndDevice from "../Constants/utils";

const MyFieldOk = (props) => {
  const { values, touched, setFieldValue } = useFormikContext();

  const [field, meta] = useField(props);

  useEffect(() => {
    if (values?.ids?.dev_eui?.trim() !== "" && touched.ids) {
      setFieldValue(props.name, `eui-${values?.ids?.dev_eui}`);
    }
  }, [touched.ids, setFieldValue, props.name, values.ids.dev_eui]);

  return (
    <>
      <input {...props} {...field} />
      {!!meta.touched && !!meta.error && <div>{meta.error}</div>}
    </>
  );
};

const handleDeviceCountChange = (e, setFieldValue) => {
  const inputVal = e.target.value;
  const numericVal = inputVal.replace(/[^\d]{0,99}/g, ""); // Only allow digits 0-9
  setFieldValue("total_devices", numericVal);
};

const validate = (values) => {
  const errors = {};

  // if (!values.ids.device_id) {
  //   errors.ids = { device_id: "Device ID is required" };
  // }

  if (values.communication_type === "LORAWAN" && values.type !== "getway") {
    if (!values.ids.dev_eui) {
      errors.ids = { ...errors.ids, dev_eui: "Dev EUI is required" };
    }
    if (values.ids.dev_eui.length !== 16) {
      errors.ids = { ...errors.ids, dev_eui: "Dev EUI is must be 16 digit" };
    }

    if (!values.ids.join_eui) {
      errors.ids = { ...errors.ids, join_eui: "Join EUI is required" };
    }

    if (values.ids.join_eui.length !== 16) {
      errors.ids = { ...errors.ids, join_eui: "Dev EUI is must be 16 digit" };
    } else if (!values.app_key) {
      errors.app_key = "App Key is required";
    }
    if (values.app_key.length !== 32) {
      errors.app_key = "app key must be 32 number";
    }

    if (!values.nwk_key) {
      errors.nwk_key = "NWK Key is required";
    }

    if (values.nwk_key.length !== 32) {
      errors.nwk_key = "NWK Key must be 32 number";
    }
  } else {
    if (!values.ids.device_id) {
      errors.ids = { ...errors.ids, device_id: "DeviceId is required" };
    }
    if (values.ids.device_id.length !== 16) {
      errors.ids = { ...errors.ids, device_id: "DeviceId is must be 16 digit" };
    }
  }

  // if (values.total_devices..length > 3) {
  //   errors.total_devices = "Number must be upto 9.";
  // }


  return errors;
};



export const RegisterSensor = ({
  isModalOpen,
  setIsModalOpen,
  editEndDeviceItem,
  setEditEndDeviceItem,
  setRender,
}) => {
  const [readings, setReadings] = useState([]);
  const [editable, setEditable] = useState(false);
  let isItArray = Array.isArray(editEndDeviceItem?.devicereadings)
  const initialValues = {
    ids: {
      device_id: editEndDeviceItem ? editEndDeviceItem?.ids?.device_id : "",
      dev_eui: editEndDeviceItem ? editEndDeviceItem?.ids?.dev_eui : "",
      join_eui: editEndDeviceItem ? editEndDeviceItem?.ids?.join_eui : "",
    },
    type:
      editEndDeviceItem && editEndDeviceItem?.type === "device"
        ? "relay"
        : editEndDeviceItem && editEndDeviceItem?.type === "sensor"
          ? "sensor"
          : "relay",
    app_key: editEndDeviceItem ? editEndDeviceItem?.app_key : "",
    nwk_key: editEndDeviceItem ? editEndDeviceItem?.nwk_key : "",
    reading: editEndDeviceItem ? isItArray && editEndDeviceItem?.devicereadings?.map((item) => item?.reading.id) : [],
    phase: editEndDeviceItem ? editEndDeviceItem.phase : "Single_Phase",
    quantity_type: editEndDeviceItem
      ? editEndDeviceItem.quantity_type
      : "Single_Device",
    total_devices: editEndDeviceItem ? editEndDeviceItem.total_devices : "",
    communication_type: editEndDeviceItem ? editEndDeviceItem?.communication_type : "LORAWAN",
  };

  const onSubmit = (values, { resetForm }) => {
    if (editEndDeviceItem) {
      if (values.type === "sensor") {
        let data = {
          reading: values?.reading,
        };

        // getEditEndRegisterdDevice({
        //   id: values.ids.device_id,
        //   type: values.type,
        //   data,
        // }).then((res) => setIsModalOpen(false));
      } else {
        let data = {
          phase: values.phase,
          quantity_type: values.quantity_type,
          total_devices: values.total_devices,
        };

        // getEditEndRegisterdDevice({
        //   id: values.ids.device_id,
        //   type: "device",
        //   data,
        // }).then((res) => {
        //   setEditable(false);
        //   setIsModalOpen(false);
        //   setRender(true);
        // });
      }
    } else {




      let getPayload = createEndDevice(values)


      if (getPayload) {
        registerEndDevices(getPayload).then((res) => {
          setIsModalOpen(false);
        });
      }




      //   if (values?.type === "relay") {
      //     let relaydata = {
      //       communication_type: values?.communication_type,
      //       device_id: `eui-${values?.ids?.device_id}`,
      //       type: "device",
      //       phase: values.phase,
      //       quantity_type: values?.quantity_type,
      //       total_devices: values?.total_devices
      //     }


      //   } else if (values.type === "sensor") {

      //     let sensorPayload = {
      //       communication_type: values?.communication_type,
      //       device_id: `eui-${values?.ids?.device_id}`,
      //       type: "sensor",
      //       reading: values?.reading
      //     }
      //   } else if (values?.type === "getway") {
      //     let getwayData = {
      //       communication_type: values?.communication_type,
      //       ids: {
      //         device_id: `eui-${values?.ids?.device_id}`,
      //         eui: values?.ids?.device_id
      //       },
      //       type: values?.type
      //     }

      //   }
      // }

      // if (values.type === "relay" && values.quantity_type === "Single_Device") {
      //   delete values.total_devices;
      //   delete values.reading;
      //   console.log(values, "if relay and single device");
      //   // registerEndDevices(values).then((res) => {
      //   //   setIsModalOpen(false);
      //   //   setEditable(false);
      //   //   setRender(true);
      //   // });
      // } else if (values.type === "relay") {
      //   delete values.reading;
      //   console.log(values, "if only relay ");
      //   // registerEndDevices(values).then((res) => {
      //   //   setIsModalOpen(false);
      //   // });
      // } else if (values.type === "sensor") {
      //   delete values.phase;
      //   delete values.quantity_type;
      //   delete values.total_devices;
      //   console.log(values, "if only sensor ");
      //   // registerEndDevices(values).then((res) => {
      //   //   setIsModalOpen(false);
      //   // });
      // } else if (values.type === "getway") {
      //   // {
      //   //   "communication_type": "LORAWAN",
      //   //     "ids": {
      //   //     "device_id": "eui-0080e11500562b0c",
      //   //       "eui": "0080e11500562b0c"
      //   //   },
      //   //   "type": "getway"
      //   // }
      //   console.log(values, "gateways values");
      // }

      resetForm();
    };
  }
  const handleCancel = () => {
    setIsModalOpen(false);
    setEditable(false);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    setEditable(false);
  };

  useEffect(() => {
    getAllSensorsReadings().then((res) => setReadings(res?.readings));
  }, []);

  return (
    <Modal
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      destroyOnClose
      closable={false}
      footer={false}
      className=""
      width={550}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
      >
        {({ errors, values, touched, setFieldValue }) => (
          <Form>
            <div className="form_wraper h-wraper-65">
              {/* category */}

              <div
                style={{
                  borderBottom: "1px solid rgba(0,0 ,0 ,0.1)",
                  padding: "20px 10px 20px 10px",
                }}
              >
                <div role="communication_type" aria-labelledby="my-radio-group">
                  <label htmlFor="nwk_key" className="me-2 my-2 w-25 fw-bold">
                    Comm. Type
                  </label>
                  <label>
                    <Field
                      type="radio"
                      name="communication_type"
                      autoComplete="off"
                      value="LORAWAN"
                      className="mx-2"
                      disabled={editEndDeviceItem && !editable}
                    />
                    LORAWAN
                  </label>
                  <label>
                    <Field
                      type="radio"
                      name="communication_type"
                      autoComplete="off"
                      value="LORA"
                      className="mx-2"
                      disabled={editEndDeviceItem && !editable}
                    />
                    LORA
                  </label>
                  <label>
                    <Field
                      type="radio"
                      name="communication_type"
                      value="WIFI"
                      autoComplete="off"
                      className="mx-2"
                      disabled={editEndDeviceItem && !editable}
                    />
                    WIFI
                  </label>
                </div>
              </div>
              {/* device type */}
              <div
                style={{
                  borderBottom: "1px solid rgba(0,0 ,0 ,0.1)",
                  padding: "20px 10px 20px 10px",
                }}
              >
                <div role="group" aria-labelledby="my-radio-group">
                  <label htmlFor="nwk_key" className="me-2 my-2 w-25 fw-bold">
                    Device Type
                  </label>
                  <label>
                    <Field
                      type="radio"
                      autoComplete="off"
                      name="type"
                      value="relay"
                      className="mx-2"
                      disabled={editEndDeviceItem && !editable}
                    />
                    Relay
                  </label>
                  <label>
                    <Field
                      type="radio"
                      name="type"
                      value="sensor"
                      autoComplete="off"
                      className="mx-2"
                      disabled={editEndDeviceItem && !editable}
                    />
                    Sesnor
                  </label>
                  {values?.communication_type === "LORAWAN" &&
                    <label>
                      <Field
                        type="radio"
                        name="type"
                        value="getway"
                        autoComplete="off"
                        className="mx-2"
                        disabled={editEndDeviceItem && !editable}
                      />
                      Getway
                    </label>}
                </div>
              </div>

              {values?.communication_type === "LORAWAN" && values?.type !== "getway" && (
                <div
                  className="py-3  px-3  w-100 align-items-center "
                  style={{ borderBottom: "1px solid #D9D4D4" }}
                >
                  <div className="d-flex align-items-center">
                    <label htmlFor="ids.dev_eui" className="me-2  w-25 fw-bold">
                      Dev EUI
                    </label>
                    <Field
                      type="text"
                      name="ids.dev_eui"
                      autoComplete="off"
                      placeholder="ex. 0080e11500560e1f"
                      className="form-control FormikInput"
                      disabled={editEndDeviceItem && true}
                    // onChange={(event, form) => {
                    //   const deviceIdField = form.getFieldProps("ids.device_id");
                    //   if (event.target.value.length === 16) {
                    //     deviceIdField.onChange(`eui-${event.target.value}`);
                    //   } else {
                    //     deviceIdField.onChange("");
                    //   }
                    // }}
                    />
                  </div>
                  <div className="Errormessage" style={{ marginLeft: "110px" }}>
                    {errors.ids && touched.ids && errors.ids.dev_eui && (
                      <div className="Errormessage">{errors.ids.dev_eui}</div>
                    )}
                  </div>
                  <div className="d-flex align-items-center">
                    <label htmlFor="ids.join_eui" className="me-2 w-25 fw-bold">
                      Join EUI
                    </label>
                    <Field
                      type="text"
                      autoComplete="off"
                      name="ids.join_eui"
                      placeholder="ex. 0000000000000000"
                      className="form-control FormikInput"
                      disabled={editEndDeviceItem && true}
                    />
                  </div>
                  <div className="Errormessage" style={{ marginLeft: "110px" }}>
                    {errors.ids && touched.ids && errors.ids.join_eui && (
                      <div className="Errormessage">{errors.ids.join_eui}</div>
                    )}
                  </div>
                </div>
              )}



              <div
                className={`${values?.communication_type === "LORAWAN" && values?.type !== "getway" ? "py-3 px-3  w-100 align-items-center" : "pt-4 px-3 w-100 align-items-center"}  `}
                style={values?.communication_type === "LORAWAN" && values?.type !== "getway" ? { borderBottom: "1px solid #D9D4D4" } : {}}
              >
                <div className="d-flex align-items-center">
                  <label
                    htmlFor="ids.device_id"
                    className="me-2 fw-bold"
                    style={{ width: "25%" }}
                  >
                    Device ID
                  </label>
                  {values?.communication_type === "LORAWAN" && values?.type !== "getway" ? (
                    <MyFieldOk
                      type="text"
                      autoComplete="off"
                      style={{ borderRadius: "10px" }}
                      readOnly
                      // value={`eui-${values.ids.dev_eui}`}
                      placeholder="device Id"
                      name="ids.device_id"
                      disabled={editEndDeviceItem && true}
                      className="form-control FormsikInput"
                    />
                  ) : (
                    <Field
                      type="text"
                      autoComplete="off"
                      style={{ borderRadius: "10px" }}
                      name="ids.device_id"
                      disabled={editEndDeviceItem && true}

                      placeholder="device Id"
                      className="form-control FormsikInput"
                    />
                  )}
                </div>
                {errors.ids && touched.ids && errors.ids.device_id && (
                  <div className="Errormessage" style={{ marginLeft: "110px" }}>
                    {errors.ids.device_id}
                  </div>
                )}
                {/* <div className="Errormessage" style={{ marginLeft: "110px" }}>
                  {errors.ids && touched.ids && touched.ids.device_id && errors.ids.device_id && (
                    <div className="Errormessage">{errors.ids.device_id}</div>
                  )}
                </div> */}
              </div>
              <div
                className="py-3 px-3  w-100 align-items-center "
                style={{ borderBottom: "1px solid #D9D4D4" }}
              >
                {values?.communication_type === "LORAWAN" && values?.type !== "getway" && (
                  <>
                    {" "}
                    <div className="d-flex align-items-center">
                      <label htmlFor="app_key" className="me-2 w-25 fw-bold">
                        App Key
                      </label>
                      <Field
                        autoComplete="off"
                        type="text"
                        placeholder="2B7E151628AED2A6ABF7158809CF4F3C"
                        name="app_key"
                        className="form-control FormikInput"
                        disabled={editEndDeviceItem && true}
                      />
                    </div>
                    <div
                      className="Errormessage"
                      style={{ marginLeft: "110px" }}
                    >
                      {errors.app_key && touched.app_key && (
                        <div className="Errormessage">{errors.app_key}</div>
                      )}
                    </div>
                  </>
                )}

                {values?.communication_type === "LORAWAN" && values?.type !== "getway" && (
                  <>
                    <div className="d-flex align-items-center">
                      <label htmlFor="nwk_key" className="me-2 w-25 fw-bold">
                        NWK Key
                      </label>
                      <Field
                        autoComplete="off"
                        type="text"
                        placeholder="2B7E151628AED2A6ABF7158809CF4F3C"
                        name="nwk_key"
                        className="form-control FormikInput"
                        disabled={editEndDeviceItem && true}
                      />
                    </div>
                    <div
                      className="Errormessage"
                      style={{ marginLeft: "110px" }}
                    >
                      {errors.nwk_key && touched.nwk_key && (
                        <div className="Errormessage">{errors.nwk_key}</div>
                      )}
                    </div>
                  </>
                )}


              </div>
              {/* if it is relay */}
              {values.type === "relay" && (
                <>
                  <div
                    style={{
                      borderBottom: "1px solid rgba(0,0 ,0 ,0.1)",
                      padding: "20px 10px 20px 10px",
                    }}
                  >
                    <div role="group" aria-labelledby="my-radio-group">
                      <label
                        htmlFor="nwk_key"
                        className="me-2 my-2 w-25 fw-bold"
                      >
                        Device Phase
                      </label>
                      <label>
                        <Field
                          type="radio"
                          name="phase"
                          autoComplete="off"
                          value="Single_Phase"
                          className="mx-2"
                          disabled={editEndDeviceItem && !editable}
                        />
                        Single Phase
                      </label>
                      <label>
                        <Field
                          type="radio"
                          name="phase"
                          value="3-Phase"
                          autoComplete="off"
                          className="mx-2"
                          disabled={editEndDeviceItem && !editable}
                        />
                        3-Phase
                      </label>
                    </div>
                  </div>
                  <div
                    style={editEndDeviceItem ? { padding: "20px 10px 20px 10px", } : {
                      borderBottom: "1px solid rgba(0,0 ,0 ,0.1)",
                      padding: "20px 10px 20px 10px",
                    }}
                  >
                    <div role="group" aria-labelledby="my-radio-group">
                      <label
                        htmlFor="nwk_key"
                        className="me-2 my-2 w-25 fw-bold"
                      >
                        Relay Device Type
                      </label>
                      <label>
                        <Field
                          type="radio"
                          name="quantity_type"
                          autoComplete="off"
                          value="Single_Device"
                          className="mx-2"
                          disabled={editEndDeviceItem && !editable}
                        />
                        Single Device Relay
                      </label>
                      <label>
                        <Field
                          type="radio"
                          name="quantity_type"
                          autoComplete="off"
                          value="Multiple_Devices"
                          className="mx-2"
                          disabled={editEndDeviceItem && !editable}
                        />
                        Multiple Device Relay
                      </label>
                    </div>
                  </div>

                  {values?.quantity_type === "Multiple_Devices" && (
                    <div
                      className="py-3 px-3  w-100 align-items-center "
                      style={editEndDeviceItem ? {} : { borderBottom: "1px solid #D9D4D4" }}
                    >
                      <div className="d-flex align-items-center">
                        <label
                          htmlFor="totoal devives"
                          className="me-2 fw-bold"
                          style={{ width: "25%" }}
                        >
                          Device Count
                        </label>
                        <Field
                          type="text"
                          value={values.total_devices}
                          placeholder="Device Count"
                          disabled={editEndDeviceItem && !editable}
                          name="total_devices"
                          className="form-control FormsikInput"
                          style={{
                            width: "135px",
                            borderRadius: "6px",
                            padding: "3px 10px",
                          }}
                          onChange={(e) =>
                            handleDeviceCountChange(e, setFieldValue)
                          }
                          autoComplete="off"
                        />
                      </div>
                      <div
                        className="Errormessage"
                        style={{ marginLeft: "110px" }}
                      >
                        {errors.total_devices &&
                          touched.total_devices &&
                          errors.total_devices && (
                            <div>{errors.total_devices}</div>
                          )}
                      </div>
                    </div>
                  )}
                </>
              )}
              {values.type === "sensor" && (
                <div
                  style={editEndDeviceItem ? { padding: "20px 10px 20px 10px", } : {
                    borderBottom: "1px solid rgba(0,0 ,0 ,0.1)",
                    padding: "20px 10px 20px 10px",
                  }}
                >
                  <div className="row">
                    <div className="col-2">
                      <span
                        style={{
                          fontSize: "14px",
                          color: "#00000",
                          fontWeight: "600",
                        }}
                      >
                        Reading &nbsp;
                      </span>
                    </div>
                    <div className="col-8">
                      <div className="row">
                        {readings?.map((option, index) => {

                          return (
                            <div key={index} className="col-4  ">
                              <div className="d-flex mb-3">
                                {" "}
                                <Field
                                  type="checkbox"
                                  name="reading"
                                  value={option.id}
                                  checked={
                                    values?.reading?.includes(option.id)
                                      ? true
                                      : false
                                  }

                                  autoComplete="off"
                                  onChange={(e) => {
                                    const readingId = parseInt(e.target.value);
                                    if (e.target.checked) {
                                      setFieldValue("reading", [
                                        ...values.reading,
                                        readingId,
                                      ]);
                                    } else {
                                      setFieldValue(
                                        "reading",
                                        values.reading.filter((id) => id !== readingId)
                                      );
                                    }
                                  }}
                                  disabled={editEndDeviceItem && !editable}
                                />
                                &nbsp;{option.reading}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {editEndDeviceItem ? (
                <></>
                // <div className="text-end me-3 pt-2">
                //   {editEndDeviceItem && editable ? (
                //     <>
                //       <Button
                //         text="Cancel"
                //         oK={handleCancel}
                //         buttonType="button"
                //         buttonStyle="global-btn secondary-btn px-2 me-2"
                //       />
                //       <Button
                //         text="Update"
                //         buttonType="submit"
                //         buttonStyle="global-btn primary-btn px-2"
                //       />
                //     </>
                //   ) : (
                //     <Button
                //       text="Edit"
                //       buttonType="button"
                //       disabled={true}
                //       icon={<BiPencil className="me-1" />}
                //       buttonStyle="global-btn deactiveButton me-3 px-4"

                //     // oK={() => setEditable(true)}
                //     />
                //   )}
                // </div>
              ) : (
                <div className="text-end me-3 pt-2">
                  <Button
                    text="Cancel"
                    oK={handleCancel}
                    buttonType="button"
                    buttonStyle="global-btn secondary-btn px-2 me-2"
                  />
                  <Button
                    text="Create"
                    buttonType="submit"
                    buttonStyle="global-btn primary-btn px-2"
                  />
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </Modal >
  );
};

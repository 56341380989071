import React, { useState } from "react";
import { useEffect } from "react";
import { BiSearchAlt } from "react-icons/bi";

const Serchbar = ({ onSearch, tab }) => {
  const [searchTerm, setSearchTerm] = useState("");

  function handleSearchInputChange(event) {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);

    // Call onSearch with the new search term

    onSearch(newSearchTerm);
  }
  useEffect(() => {
    setSearchTerm("");
  }, [tab]);
  return (
    <div className="serchbar-section">
      {/* serchIcon */}
      <div className="serch-icon">
        <BiSearchAlt />
      </div>
      {/* Input field */}
      <div className="search-input">
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearchInputChange}
          maxLength="20"
        />
      </div>
    </div>
  );
};

export default Serchbar;

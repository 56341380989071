import React from "react";
import { useEffect } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { MdDone } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { emailVarification } from "../../ApisURl/ApisUrl";

const EmailVarification = ({}) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const onRedirectLogin = () => {
    navigate("/");
  };

  useEffect(() => {
    if (typeof id === "string") {
      emailVarification(id).then((res) =>
        console.log("succefully sdjhfhsd", res)
      );
    }
  }, []);

  return (
    <div className="email-varifed-section  mt-5 ">
      <div
        className=" mx-auto d-flex flex-column align-items-center justify-content-center"
        style={{ width: "45%" }}
      >
        <div className="email-varified-icon">
          <div className="right-icon">
            <MdDone />
          </div>
        </div>
        <span className="fw-bold cr">Congratulations!</span>
        <p className="pt-1 pb-5">
          Your email has been successfully verified. Thank you for confirming
          your email address. Your account is now active.
        </p>

        <button className="back-btn" onClick={onRedirectLogin}>
          <FaArrowLeft /> &nbsp; Back to login
        </button>
      </div>
    </div>
  );
};

export default EmailVarification;

import moment from "moment";
import React, { useState, useEffect } from "react";
import { AiOutlineDash, AiOutlinePlus } from "react-icons/ai";
import { BiBroadcast, BiMobileAlt, BiTrash } from "react-icons/bi";
import { BsInbox } from "react-icons/bs";
import { HiOutlineMail } from "react-icons/hi";
import { Button } from "antd";
import { MdOutlineHomeWork } from "react-icons/md";
import { RiLeafLine } from "react-icons/ri";
import ReactPaginate from "react-paginate";
import {
  deleteMultiSensorAndDevice,
  deleteSensorAndDevice,
  getAllFarmers,
} from "../../../ApisURl/ApisUrl";
// import Button from "../../../Component/Button";

import CreateFarmer from "./CreateFarmerModals/CreateFarmer";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userPlan } from "../../../Store/Slices/CurrentPlan";

const Items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
const AllEnquiry = ({
  farmer,
  setfarmer,
  filterRows,
  pageChnages,
  setPageChnage,
}) => {
  const [selectedItems, setSelectedItems] = useState([]);

  const [editFarmerRecord, setEditFarmerRecord] = useState();

  const [showDeviceModal, setShowDeviceModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [render, setRender] = useState(false);

  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + 10;
  let EnquiryUser = farmer?.farmers?.filter((user) => {
    if (user?.farmer?.enquiry_flag === false) {
      return user;
    }
  });

  const currentItems =
    filterRows?.serchText?.length !== 0
      ? filterRows?.filterRow?.slice(itemOffset, endOffset) || []
      : EnquiryUser?.slice(itemOffset, endOffset) || [];

  const pageCount =
    filterRows?.serchText?.length !== 0
      ? Math.ceil(filterRows?.filterRow?.length / 10)
      : Math.ceil(EnquiryUser?.length / 10);

  const handlePageClick = (event) => {
    const newOffset =
      filterRows?.serchText?.length !== 0
        ? (event.selected * 10) % filterRows?.filterRow?.length
        : (event.selected * 10) % EnquiryUser?.length;

    setItemOffset(newOffset);
    setPageChnage(true);
  };

  const DeviceIds = selectedItems && selectedItems?.map((item) => item.id);

  const handleSelectItem = (e, okData, DataType) => {
    if (e.target.checked === true && DataType === "allData") {
      setSelectedItems(okData);
      setPageChnage(false);
    } else if (selectedItems?.includes(okData)) {
      const NewObj = selectedItems?.filter((item) => {
        return item.id !== okData.id;
      });
      setSelectedItems(NewObj);
    } else if (!DeviceIds?.includes(okData.id) && DataType !== "allData") {
      setSelectedItems([...selectedItems, okData]);
    } else {
      setSelectedItems([]);
    }
  };

  useEffect(() => {
    setRender(false);
    getAllFarmers().then((res) => setfarmer(res));
  }, [isModalOpen, render, showDeviceModal]);

  // #EDIT DEVICE
  const editDeviceItem = (DeviceItem) => {
    setEditFarmerRecord(DeviceItem);
    setIsModalOpen(true);
  };

  // Delete Device
  const DeviceDeleteHandler = () => {
    // if (DeviceIds?.length === 1) {
    //   deleteSensorAndDevice(DeviceIds && DeviceIds?.[0]).then((res) => {
    //     if (res) {
    //       const NewObj = selectedItems?.filter((item) => {
    //         return item.id !== DeviceIds?.[0];
    //       });
    //       setSelectedItems(NewObj);
    //     }
    //   });
    //   setRender(true);
    // } else {
    //   deleteMultiSensorAndDevice({ deviceIds: DeviceIds });
    //   const NewObj = selectedItems?.filter((item) => {
    //     return !DeviceIds?.includes(item.id);
    //   });
    //   setSelectedItems(NewObj);
    //   setRender(true);
    // }
  };

  const goAssignPlan = (data) => {
    dispatch(userPlan(data));
    localStorage.setItem("userPlan", JSON.stringify(data));
    navigate("/currentplan");
  };

  console.log(farmer, "farmer length");

  return (
    <div className="position-relative">
      <div
        className="alert-header-buttons-group"
        style={{ position: "absolute", top: "-57px", right: "20px" }}
      >
        {/* <Button
          text="Delete"
          icon={<BiTrash className="Tabs-icons" />}
          oK={() => DeviceDeleteHandler()}
          buttonStyle={
            selectedItems?.length === 0
              ? "global-btn deleteDeactive me-3 btn-padding"
              : "global-btn green-btn me-3 btn-padding"
          }
        /> */}
      </div>
      <table className="table mb-4">
        <thead>
          <tr>
            {/* <th>
              <input
                type="checkbox"
                onClick={(e) => handleSelectItem(e, currentItems, "allData")}
                value=""
                checked={
                  pageChnages
                    ? true
                    : currentItems?.length === selectedItems?.length
                    ? true
                    : false
                }
              />
            </th> */}
            <th>
              {/* <span className="table-icons">
                <BiBroadcast />
              </span> */}
              Farmer Name
            </th>
            <th>
              <span className="table-icons">
                <HiOutlineMail />
              </span>
              Email
            </th>{" "}
            <th>
              <span className="table-icons">
                <BiMobileAlt />
              </span>{" "}
              Mobile Number
            </th>
            <th>
              <span className="table-icons">
                <MdOutlineHomeWork />
              </span>
              Company Name
            </th>{" "}
            <th>Status</th>
            <th>Manage</th>
          </tr>
        </thead>
        <tbody>
          {currentItems?.map((ele, index) => {
            return (
              <tr key={ele.farmer.id}>
                {/* <td>
                    <input
                      type="checkbox"
                      onClick={(e) => handleSelectItem(e, ele.farmer)}
                      value={ele.farmer}
                      checked={
                        pageChnages
                          ? false
                          : selectedItems.includes(ele.farmer)
                          ? true
                          : false
                      }
                    />
                  </td> */}
                <td
                  onClick={() => editDeviceItem(ele.farmer)}
                  className="linkable"
                  style={{ cursor: "pointer" }}
                >
                  {ele.farmer?.first_name}&nbsp;
                  {ele?.farmer?.last_name}
                </td>
                <td>{ele?.farmer?.email}</td>
                <td>{ele?.farmer?.contact_No || <AiOutlineDash />} </td>
                <td>{ele?.farmer?.company_name || <AiOutlineDash />}</td>
                <td>
                  {ele?.farmer?.status === "active" ? "Active " : "InActive"}
                </td>
                <td>
                  <Button onClick={() => goAssignPlan(ele)}>
                    Assign Subscription
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {currentItems?.length === 0 && (
        <div className="d-flex flex-column justify-content-center align-items-center mb-3">
          <div style={{ fontSize: "50px", color: "#a9b6c2" }}>
            <BsInbox />
          </div>
          <p style={{ color: "#a9b6c2" }}>No Record Found</p>
        </div>
      )}
      <div className="paginate">
        {currentItems?.length !== 0 && EnquiryUser?.length >= 10 && (
          <ReactPaginate
            breakLabel="..."
            nextLabel={">"}
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel={"<"}
            renderOnZeroPageCount={null}
          />
        )}
      </div>
      <div className="global-button add-btn pb-4 text-end">
        <button
          className="global-btn primary-btn btn-padding"
          type="button"
          onClick={() => {
            setIsModalOpen(true);
            setEditFarmerRecord("");
          }}
        >
          Add Enquiry <AiOutlinePlus />
        </button>
      </div>

      <CreateFarmer
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        editRecord={editFarmerRecord}
      />
    </div>
  );
};

export default AllEnquiry;

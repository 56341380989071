import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { AiOutlineNodeIndex } from "react-icons/ai";
import { BiSpreadsheet } from "react-icons/bi";
import Serchbar from "../../../Component/Serchbar/Serchbar";
import AvailableSensors from "../../../Page/Settings/RegisterSensor/AvailableSensors";
import ReadingsSuperAdmin from "./ReadingsSuperAdmin";

const SettingsSuper = () => {
  const [tab, setTab] = useState("0");
  const [pageChnages, setPageChnage] = useState(false);
  const [allTtnSensors, setAllTtnSensors] = useState([]);
  const [DeviceReadings, setDeviceReadings] = useState([]);
  const [filterRows, setFilterRows] = useState({
    filterRow: [],
    serchText: "",
  });

  function performSearch(regex) {
    if (tab === "0") {
      return allTtnSensors?.filter(
        (item) =>
          regex.test(item?.ids?.device_id) ||
          regex.test(item?.status) ||
          regex.test(item.type)
      );
    } else if (tab === "1") {
      return DeviceReadings?.filter((item) => regex.test(item?.reading));
    }
  }

  function handleSearch(newSearchTerm) {
    const regex = new RegExp(newSearchTerm, "i");
    const result = performSearch(regex);
    setFilterRows({
      ...filterRows,
      filterRow: result,
      serchText: newSearchTerm,
    });
  }
  return (
    <div className="setting-section">
      <div className="setting-section-header d-flex justify-content-between align-items-center">
        <h1 className="title mt-2 d-flex flex-column" style={{ gap: "15px" }}>
          Settings
          {/* <span className=" sub-title ">Product Management</span> */}
        </h1>
        <Serchbar onSearch={handleSearch} tab={tab} />
      </div>

      <div className="setting-body-section position-relative">
        <div className="setting-table">
          <Tabs
            defaultActiveKey={tab}
            id="uncontrolled-tab-example"
            className="mb-3"
            onSelect={(k) => {
              setTab(k);
              setFilterRows({
                filterRow: [],
                serchText: "",
              });
            }}
          >
            <Tab
              eventKey="0"
              title={
                <span>
                  <span className="table-icons">
                    <AiOutlineNodeIndex />{" "}
                  </span>{" "}
                  Register end device
                </span>
              }
            >
              <AvailableSensors
                filterRows={filterRows}
                allTtnSensors={allTtnSensors}
                setAllTtnSensors={setAllTtnSensors}
                pageChnages={pageChnages}
                setPageChnage={setPageChnage}
              />
            </Tab>

            <Tab
              eventKey="1"
              title={
                <span>
                  <span className="table-icons">
                    <BiSpreadsheet />{" "}
                  </span>{" "}
                  Readings
                </span>
              }
            >
              <ReadingsSuperAdmin
                filterRows={filterRows}
                DeviceReadings={DeviceReadings}
                setDeviceReadings={setDeviceReadings}
                pageChnages={pageChnages}
                setPageChnage={setPageChnage}
              />
            </Tab>
            {/* GiMovementSensor */}
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default SettingsSuper;

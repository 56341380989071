import React from "react";

const Index = ({
  text,
  buttonStyle,
  buttonType,
  icon,
  righIcon,
  oK,
  props,
}) => {
  return (
    <button
      className={`${buttonStyle}`}
      type={`${buttonType}`}
      onClick={oK}
      {...props}
    >
      <span className="button-left-icon">{icon}</span> {text}{" "}
      <span className="button-right-icon">{righIcon}</span>
    </button>
  );
};

export default Index;

import axios, { Axios } from "axios";
import { toast } from "react-toastify";
import instance from "./AxiosInstance";

// ========================= user ==============================

export const registerFarmer = async (params) => {
  try {
    let Response = await instance.post(`users`, params, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    // if ((Response.status = "200")) {
    //   return toast.success("Registration completed successfully");
    // } else {
    //   toast.error("Registration failed");
    // }
    return Response;
  } catch (error) {
    return toast.error(`${error?.response?.data?.message}`, {
      autoClose: 1000,
    });
  }
};

export const emailVarification = async (id) => {
  try {
    let res = await instance.put(`users/email_verification/${id}`);
    if (res.status === 200) {
      return res.data;
    }
  } catch (error) {
    return error;
  }
};

export const userLogin = async (params) => {
  try {
    let res = await instance.post(`/users/login`, params);
    if (res.status === 200) {
      // if (res.data.data.staus === "active") {
      //   const id = toast.loading("Please wait...");
      //   toast.update(id, {
      //     render: "Logged in successfully",
      //     type: "success",
      //     isLoading: false,
      //     autoClose: 1000,
      //     hideProgressBar: true,
      //   });
      // }

      return res.data;
    }
  } catch (error) {
    // const id = toast.loading("Please wait...");
    // toast.update(id, {
    //   render: "Something went wrong, check your credentials",
    //   type: "error",
    //   isLoading: false,
    //   autoClose: 1000,
    //   hideProgressBar: true,
    // });
    // toast.error("Something went wrong!", { autoClose: 1000 });
    return toast.error(`${error?.response?.data?.message}`, {
      autoClose: 1000,
    });
  }
};

export const userLoginWithGoogle = async (params) => {
  // const id = toast.loading("Please wait...");
  try {
    let res = await instance.post(`/users/login_google`, params);
    if (res.status === 200) {
      // toast.update(id, {
      //   render: "Logged in successfully",
      //   type: "success",
      //   isLoading: false,
      //   autoClose: 1000,
      //   hideProgressBar: true,
      // });
      return res.data;
    }
  } catch (error) {
    // toast.update(id, {
    //   render: "Something went wrong",
    //   type: "error",
    //   isLoading: false,
    //   autoClose: 1000,
    //   hideProgressBar: true,
    // });
    toast.error("Something went wrong!!", { autoClose: 1000 });
    return error;
  }
};

// toast.promise(userLogin, {
//   pending: "Promise is pending",
//   success: "Promise resolved 👌",
//   error: "Promise rejected 🤯",
// });

// -- get profile --
export const getProfile = async () => {
  try {
    let res = await instance.get(`/users/profile`);
    if (res.status === 200) {
      return res.data.data;
    }
  } catch (error) {
    return error;
  }
};

export const getUserProfileImage = async () => {
  try {
    let res = await instance.get(`/users/profile`);
    if (res.status === 200) {
      return res.data.data;
    }
  } catch (error) {
    return error;
  }
};
export const updateProfilePicture = async (body) => {
  try {
    let res = await instance.post(`/users/upload`, body, {
      headers: {
        "Content-type": "multipart/form-data",
      },
    });
    if (res.status === 200) {
      return res.data;
    }
  } catch (error) {
    return error;
  }
};

// -- edit profile --
export const editProfile = async (params) => {
  const id = toast.loading("Please wait...");
  try {
    let res = await instance.put(`/users/edit`, params);
    if (res.status === 200) {
      toast.update(id, {
        render: "your profile successfully updated",
        type: "success",
        isLoading: false,
        autoClose: 2000,
        hideProgressBar: true,
      });
      return res.data.data;
    }
  } catch (error) {
    toast.update(id, {
      render: "Something went wrong",
      type: "error",
      isLoading: false,
      autoClose: 3000,
      hideProgressBar: true,
    });
    return error;
  }
};

// -- Reset password --
export const resetPassword = async (paramsBody) => {
  let { id, pwd } = paramsBody;
  try {
    let res = await instance.put(`users/reset-password/${id}`, pwd);
    if (res.status === 200) {
      return res.data;
    }
  } catch (error) {
    return error;
  }
};

export const authLogOut = async () => {
  try {
    let res = await instance.put(`/users/logout`);
    if (res.status === 200) {
      return res;
    }
  } catch (error) {
    return error;
  }
};

// =============================Group========================
//-- create group --
export const createGroup = async (params) => {
  try {
    let Response = await instance.post(`/groups/`, params);
    if (Response.status === 200) {
      return Response.data;
    }
  } catch (error) {
    return error;
  }
};
//-- Edit group --
export const editGroup = async (params) => {
  try {
    let Response = await instance.put(`/groups/edit`, params, {
      headers: {
        Authorization: `${localStorage.getItem("user_token")}`,
      },
    });
    if (Response.status === 200) {
      return Response.data;
    }
  } catch (error) {
    return error;
  }
};
// --Delete group --
export const deleteGroup = async (paramsId) => {
  try {
    let Response = await instance.delete(`/groups/delete/${paramsId}`);
    if (Response.status === 200) {
      return Response.data;
    }
  } catch (error) {
    return error;
  }
};
// --view All Group --
export const getAllGroups = async () => {
  try {
    let Response = await instance.get(`/groups`);
    if (Response.status === 200) {
      return Response.data;
    }
  } catch (error) {
    return error;
  }
};

// --searchGroup --
const getSingleGroups = async (paramsId) => {
  try {
    let Response = await instance.delete(`/groups/${paramsId}`);
    if (Response.status === 200) {
      return Response.data;
    }
  } catch (error) {
    return error;
  }
};

// =============================Farms========================

// --create farm --
export const createFarm = async (params) => {
  try {
    let Response = await instance.post(`/farms/`, params);
    if (Response.status === 200) {
      return Response.data;
    }
  } catch (error) {
    return error;
  }
};
//--Edit Farm --
export const editFarm = async (params) => {
  try {
    let Response = await instance.put(`/farms/edit`, params);
    if (Response.status === 200) {
      return Response.data;
    }
  } catch (error) {
    return error;
  }
};
// --Delete Farm --
export const DeleteFarm = async (paramsID) => {
  try {
    let Response = await instance.delete(`/farms/delete/${paramsID}`);
    if (Response.status === 200) {
      return Response.data;
    }
  } catch (error) {
    return error;
  }
};

// --Delete Farms --
export const DeleteMultipleFarms = async (params) => {
  try {
    let Response = await instance.delete(`/farms/deleteFarms`, {
      data: params,
    });
    if (Response.status === 200) {
      return Response.data;
    }
  } catch (error) {
    return error;
  }
};
// --Move farm --

export const moveFarm = async (params) => {
  let { farmID, groupID } = params;
  try {
    let Response = await instance.put(
      `/farms/moveFarm/${farmID}/${groupID}`,
      params
    );
    if (Response.status === 200) {
      return Response.data;
    }
  } catch (error) {
    toast.error("farm Name must be unique");
    return error;
  }
};
// --Move Farms --
export const moveMultipleFarms = async (params) => {
  let { farmID, groupID } = params;
  try {
    let Response = await instance.put(`/farms/moveFarms/${groupID}`, farmID);
    if (Response.status === 200) {
      return Response.data;
    }
  } catch (error) {
    toast.error("farm Name must be unique");
    return error;
  }
};

export const getFarmWiseDevices = async (paramsId) => {
  try {
    let Response = await instance.get(`/farms/${paramsId}`);
    if (Response.status === 200) {
      return Response.data;
    }
  } catch (error) {
    return error;
  }
};

// getFarm by groupwise
export const getFarmsGroupWise = async (paramsID) => {
  try {
    let res = await instance.get(`/farms/getFarmsByGroupId/${paramsID}`);
    if (res.status === 200) {
      return res.data.data;
    }
  } catch (error) {
    return error;
  }
};

// Add Sensor Device
export const addSensor = async (paramsBody) => {
  try {
    let res = await instance.post(`/devices/sensor`, paramsBody);

    if (res.status === 200) {
      return res.data.data;
    }
  } catch (error) {
    return error;
  }
};

// Add  Device
export const addDevice = async (paramsBody) => {
  try {
    let res = await instance.post(`/devices/device`, paramsBody);
    if (res.status === 200) {
      return res;
    }
  } catch (error) {
    return error;
  }
};

// Assign Sensor to the farm
export const AssigneSensor = async (paramsBody) => {
  try {
    let res = await instance.put(`/devices/assign_sensor_to_farm`, paramsBody);

    if (res.status === 200) {
      return res;
    }
  } catch (error) {
    return error;
  }
};

// Assign Device to the farm
export const AssigneDevice = async (paramsBody) => {
  try {
    let res = await instance.put(`/devices/assign_device_to_farm`, paramsBody);

    if (res.status === 200) {
      return res;
    }
  } catch (error) {
    return error;
  }
};

// DELETE SENSOR AND DEVICE
export const deleteSensorAndDevice = async (paramsId) => {
  try {
    let res = await instance.delete(`/devices/delete/${paramsId}`);

    if (res.status === 200) {
      return res.data.data;
    }
  } catch (error) {
    return error;
  }
};

export const deleteMultiSensorAndDevice = async (paramsBody) => {
  const { deviceIds } = paramsBody;
  return await instance
    .delete(`/devices/deleteDevices`, { data: { deviceIds: deviceIds } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

// GET ALL SENOSR
export const getAllSensor = async () => {
  try {
    let res = await instance.get(`/devices/sensors`);

    if (res.status === 200) {
      return res.data.data;
    }
  } catch (error) {
    return error;
  }
};

// GET ALL DEVICE
export const getAllDevice = async () => {
  try {
    let res = await instance.get(`/devices/devices`);

    if (res.status === 200) {
      return res.data.data;
    }
  } catch (error) {
    return error;
  }
};

// EDIT SENSOR
export const editSensor = async (paramsBody) => {
  try {
    let res = await instance.put(`/devices/sensor/edit`, paramsBody);

    if (res.status === 200) {
      return res.data.data;
    }
  } catch (error) {
    return error;
  }
};
// EDIT DEVICE
export const ediDevice = async (paramsBody) => {
  try {
    let res = await instance.put(`/devices/device/edit`, paramsBody);

    if (res.status === 200) {
      return res;
    }
  } catch (error) {
    return error;
  }
};

// **********************************//
// All Alert
// **********************************//

export const getAllAlerts = async () => {
  try {
    let res = await instance.get("/alerts");
    return res.data.data;
  } catch (error) {
    return error;
  }
};

// ========================= CREATE TEMPLATE ==============================

// CREATE TEMPLATE

export const createTemplate = async (paramsBody) => {
  try {
    let res = await instance.post(`/templates`, paramsBody);
    if (res.status === 200) {
      return res.data.data;
    }
  } catch (error) {
    return error;
  }
};

// EDIT TEMPLATE

export const editTemplate = async (paramsId) => {
  const { id, body } = paramsId;

  try {
    let res = await instance.put(`/templates/edit/${id}`, body);
    if (res.status === 200) {
      return res.data.data;
    }
  } catch (error) {
    return error;
  }
};

// GET TEMPLATES

export const getTemplates = async () => {
  try {
    let res = await instance.get(`/templates`);
    if (res.status === 200) {
      return res.data.data;
    }
  } catch (error) {
    return error;
  }
};

// Read alert
export const onReadHandler = async (paramsBody) => {
  try {
    let res = await instance.put(`/alerts`, {
      alertIds: paramsBody,
    });

    if (res.status === 200) {
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const deleteAlerts = async (paramseIds) => {
  try {
    let res = await instance.delete(`/alerts`, {
      data: {
        alertIds: paramseIds,
      },
    });

    if (res.status === 200) {
      return res;
    }
  } catch (error) {
    return error;
  }
};

// GET SINGE TEMPLATES

export const getSingleTemplates = async (paramsId) => {
  try {
    let res = await instance.get(`/templates/${paramsId}`);
    if (res.status === 200) {
      return res.data.data;
    }
  } catch (error) {
    return error;
  }
};

// DELETE TEMPLATE
export const deleteTemlates = async (paramsId) => {
  try {
    let res = await instance.delete(`/templates/delete/${paramsId}`);

    if (res.status === 200) {
      return res;
    }
  } catch (error) {
    return error;
  }
};

// DELETE TEMPLATES
export const deleteMultiTemplates = async (paramsBody) => {
  try {
    let res = await instance.delete(`/templates/deleteTemplates`, {
      data: {
        templateIds: paramsBody,
      },
    });

    if (res.status === 200) {
      return res;
    }
  } catch (error) {
    return error;
  }
};

// #TEMPLATE

// get all farm by group
export const getFarmBygroup = async (paramsBody) => {
  try {
    let res = await instance.get(`/automations/groups/`, {
      params: paramsBody,
    });

    if (res.status === 200) {
      return res.data.data;
    }
  } catch (error) {
    return error;
  }
};
// get sensor accordingg to the farms
export const getSenosrByfarm = async (paramsBody) => {
  try {
    let res = await instance.get(`/automations/farms/`, {
      params: paramsBody,
    });

    if (res.status === 200) {
      return res.data.data;
    }
  } catch (error) {
    return error;
  }
};

// ***************************//
// *    Automation           *//
// ***************************//

// CREATE Automation

export const createAutomation = async (paramsBody) => {
  try {
    let res = await instance.post(`/automations`, paramsBody);
    if (res.status === 200) {
      return res.data.data;
    }
  } catch (error) {
    return error;
  }
};

// EDIT TEMPLATE

export const editAutomation = async (paramsId) => {
  const { id, body } = paramsId;

  try {
    let res = await instance.put(`/automations/edit/${id}`, body);
    if (res.status === 200) {
      return res.data.data;
    }
  } catch (error) {
    return error;
  }
};

export const editAutomationStatus = async (paramsId) => {
  const { id, body } = paramsId;

  try {
    let res = await instance.put(
      `/automations/updateAutomation_status/${id}`,
      body
    );
    if (res.status === 200) {
      return res.data.data;
    }
  } catch (error) {
    return error;
  }
};

// GET TEMPLATES

export const getAutomations = async () => {
  try {
    let res = await instance.get(`/automations`);
    if (res.status === 200) {
      return res.data.data;
    }
  } catch (error) {
    return error;
  }
};

// GET SINGE TEMPLATES

export const getSingleAutomation = async (paramsId) => {
  try {
    let res = await instance.get(`/automations/${paramsId}`);
    if (res.status === 200) {
      return res.data.data;
    }
  } catch (error) {
    return error;
  }
};

// DELETE TEMPLATE
export const deleteAutomation = async (paramsId) => {
  try {
    let res = await instance.delete(`/automations/delete/${paramsId}`);

    if (res.status === 200) {
      return res;
    }
  } catch (error) {
    return error;
  }
};

// DELETE TEMPLATES
export const deleteMultiAutomations = async (paramsBody) => {
  try {
    let res = await instance.delete(`/automations/deleteAutomations`, {
      data: {
        automationIds: paramsBody,
      },
    });

    if (res.status === 200) {
      return res;
    }
  } catch (error) {
    return error;
  }
};

// Get Assigned Sensor

export const getAssignPortBasedOnDeviceId = async (id) => {
  try {
    let res = await instance.get(`automations/assigned_ports/${id}`);
    return res.data.data;
  } catch (error) {
    return error;
  }
};

// ***************************//
// *    Registered Sensors   *//
// ***************************//

export const getRegisterdSensors = async () => {
  try {
    let res = await instance.get(`/devices/viewAll_endDevices`);
    return res.data.data;
  } catch (error) {
    return error;
  }
};

export const getEditEndRegisterdDevice = async (body) => {
  let { id, type, data } = body;
  try {
    let res = await instance.put(
      `devices/sensorordevice/edit?serial_number=${id}&type=${type}`,
      data
    );

    return res.data.data;
  } catch (error) {
    return error;
  }
};

export const getSensorDatabyDate = async (sensorBody) => {
  // const dates = { farmId: 111, date: "2023-03-30" };
  const { farmId, date, month, year } = sensorBody;

  try {
    let res = await instance.get(
      `/sensorData/viewSensorDataByFarmAndDate/${farmId}/${year}-${month}-${date}`
    );

    return res.data.data;
  } catch (error) {
    return error;
  }
};

export const getChartDataByDateWise = async (paramsBody) => {
  // "sensorData/sensorDataByFarmAndRange?farmId=2&startDate=2023-07-23T18:30:00.000Z&endDate=2023-07-24T18:30:00.000Z%27"
  const { farmId, date, month, year, endDate, startDate } = paramsBody;

  try {
    let res = await instance.get(
      `sensorData/sensorDataByFarmAndRange?farmId=${farmId}&startDate=${startDate}&endDate=${endDate}`
    );
    return res.data.data;
  } catch (error) {
    return error;
  }
};

export const getSensorDatabyMonth = async (sensorBody) => {
  const { farmId, month, year } = sensorBody;
  try {
    let res = await instance.get(
      `/sensorData/viewSensorDataByFarmAndMonth/${farmId}/${month}/${year}`
    );
    return res?.data?.data;
  } catch (error) {
    return error;
  }
};

export const getSensorDatabyWeek = async (sensorBody) => {
  const { farmId, startDate, endDay } = sensorBody;
  try {
    let res = await instance.get(
      `sensorData/viewSensorDataByFarmAndWeek/${farmId}/${startDate}/${endDay}`
    );
    return res;
  } catch (error) {
    return error;
  }
};

// Register device

export const registerEndDevices = async (deviceBody) => {
  try {
    let res = await instance.post(`devices/register/sensor`, deviceBody);
    return res;
  } catch (error) {
    return error;
  }
};

export const getDeviceDetailBySerialNumber = async (id) => {
  try {
    let res = await instance.get(`devices/sensor/${id}`);
    return res.data.data;
  } catch (error) {
    return error;
  }
};

// *****************************//
//   Reading Functionality      //
// *****************************//

export const getUnitReadings = async (query) => {
  try {
    let res = await instance.get(`/readings/viewUnits?unit=${query}`);
    return res.data.data;
  } catch (error) {
    return error;
  }
};

export const getAllSensorsReadings = async () => {
  try {
    let res = await instance.get(`readings/sensor`);
    return res.data.data;
  } catch (error) {
    return error;
  }
};

export const getAllDeviceReadings = async () => {
  try {
    let res = await instance.get(`readings/device`);
    return res.data.data;
  } catch (error) {
    return error;
  }
};

// Super Admin

export const getAllFarmers = async () => {
  try {
    let res = await instance.get(`/users/all_farmers`);
    return res.data.data;
  } catch (error) {
    return error;
  }
};
// Create pacckage -super admin side
export const createPackage = async (values) => {
  try {
    let res = await instance.post("/packages", values);
    return res.data.data;
  } catch (error) {
    return error;
  }
};

// Edit pacckage -super admin side
export const editPackage = async (data) => {
  let { id, body } = data;
  try {
    let res = await instance.put(`/packages/${id}`, body);
    return res.data.data;
  } catch (error) {
    return error;
  }
};

// get pacckage -super admin side
export const getAllPackages = async (id) => {
  try {
    let res = await instance.get(`/packages`);
    return res.data.data;
  } catch (error) {
    return error;
  }
};

// Delete pacckage -super admin side
export const deletePackage = async (paramsBody) => {
  try {
    let res = await instance.delete(`/packages/`, {
      data: {
        packageIds: paramsBody,
      },
    });
    return res;
  } catch (error) {
    return error;
  }
};

// toast Message

const showToast = () => {
  let toastId = null; // Variable to store the toast ID
  if (toastId) {
    toast.update(toastId, {
      render: "Package assigned succesfully",
      type: toast.TYPE.SUCCESS,
      autoClose: 1000,
    });
  } else {
    toastId = toast.success("Package assigned succesfully", {
      autoClose: 1000,
    });
  }
};

export const assignNewSubscription = async (body) => {
  try {
    let res = await instance.post(`/Subscriptionhistory`, body);
    if (res?.data?.status === 200) {
      // toast.success(`${res?.data?.message}`, { autoClose: 1000 });
      // showToast();

      return res.data;
    }

    return res.data.data;
  } catch (error) {
    toast.error("Packages is already exist", { autoClose: 1000 });
    return error;
  }
};

export const EditSubscriptionByUser = async (id) => {
  try {
    let res = await instance.post(`/Subscriptionhistory/${id}`, "body");
  } catch (error) {
    return error;
  }
};

export const getUsersAllSubscriptions = async (id) => {
  try {
    let res = await instance.get(`/Subscriptionhistory/${id}`);
    return res.data.data;
  } catch (error) {
    return error;
  }
};

// ************************************//
//        Wether api call             //
// ***********************************//

export const getWetherRecords = async ({ lat, long }) => {
  try {
    let res = await instance.get(`/weather_data?q=${(lat, long)}`);
    return res.data;
  } catch (error) {
    return error;
  }
};

//readings

export const getAddReadingsInAdmin = async (body) => {
  try {
    let res = await instance.post(`/readings`, body, {
      headers: {
        "Content-type": "multipart/form-data",
      },
    });
    if (res.status === 200) {
      return res.data;
    }
  } catch (error) {
    return error;
  }
};
export const getEditReadingsInAdmin = async (body) => {
  let { id, data } = body;
  try {
    let res = await instance.put(`/readings/${id}`, data, {
      headers: {
        "Content-type": "multipart/form-data",
      },
    });
    if (res.status === 200) {
      return res.data;
    }
  } catch (error) {
    return error;
  }
};

// --Delete redai --
export const ondeleteReadings = async (params) => {
  try {
    let Response = await instance.delete(`/readings`, {
      data: {
        Ids: params,
      },
    });
    if (Response.status === 200) {
      return Response.data;
    }
  } catch (error) {
    return error;
  }
};

import React, { useState, useEffect } from "react";
import { BiBroadcast, BiBuoy, BiMobileAlt, BiTrash } from "react-icons/bi";
import { AiOutlineDash } from "react-icons/ai";
import { BsInbox } from "react-icons/bs";
import { HiOutlineMail } from "react-icons/hi";
import { MdOutlineHomeWork } from "react-icons/md";
import { RiLeafLine } from "react-icons/ri";
import ReactPaginate from "react-paginate";
import { getAllFarmers } from "../../../ApisURl/ApisUrl";
import Button from "../../../Component/Button";
import DevicePopUp from "../../Component/DevicePopUp";
import SubscriptionRecord from "../../Component/SubscriptionRecord";
import CreateFarmer from "./CreateFarmerModals/CreateFarmer";

const Items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
const AllFarmers = ({
  farmer,
  setfarmer,
  filterRows,
  pageChnages,
  setPageChnage,
}) => {
  const [selectedItems, setSelectedItems] = useState([]);

  const [editDevicesItem, setEditDevicesItem] = useState();

  const [showDeviceModal, setShowDeviceModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [render, setRender] = useState(false);

  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + 10;

  let FarmerUser = farmer?.farmers?.filter((user) => {
    if (user?.farmer?.enquiry_flag === true) {
      return user;
    }
  });
  const currentItems =
    filterRows?.serchText?.length !== 0
      ? filterRows?.filterRow?.slice(itemOffset, endOffset) || []
      : FarmerUser?.slice(itemOffset, endOffset) || [];

  const pageCount =
    filterRows?.serchText?.length !== 0
      ? Math.ceil(filterRows?.filterRow?.length / 10)
      : Math.ceil(FarmerUser?.length / 10);

  const handlePageClick = (event) => {
    const newOffset =
      filterRows?.serchText?.length !== 0
        ? (event.selected * 10) % filterRows?.filterRow?.length
        : (event.selected * 10) % FarmerUser?.length;

    setItemOffset(newOffset);
    setPageChnage(true);
  };

  const onChange = () => {};

  const DeviceIds = selectedItems && selectedItems?.map((item) => item.id);

  const handleSelectItem = (e, okData, DataType) => {
    if (e.target.checked === true && DataType === "allData") {
      setSelectedItems(okData);
      setPageChnage(false);
    } else if (selectedItems?.includes(okData)) {
      const NewObj = selectedItems?.filter((item) => {
        return item.id !== okData.id;
      });
      setSelectedItems(NewObj);
    } else if (!DeviceIds.id?.includes(okData.id) && DataType !== "allData") {
      setSelectedItems([...selectedItems, okData]);
    } else {
      setSelectedItems([]);
    }
  };

  useEffect(() => {
    setRender(false);
    getAllFarmers().then((res) => setfarmer(res));
  }, [isModalOpen, render, showDeviceModal]);

  // #EDIT DEVICE
  const editDeviceItem = (DeviceItem) => {
    setEditDevicesItem(DeviceItem);
    setIsModalOpen(true);
  };

  // Delete Device
  const DeviceDeleteHandler = () => {
    // if (DeviceIds?.length === 1) {
    //   deleteSensorAndDevice(DeviceIds && DeviceIds?.[0]).then((res) => {
    //     if (res) {
    //       const NewObj = selectedItems?.filter((item) => {
    //         return item.id !== DeviceIds?.[0];
    //       });
    //       setSelectedItems(NewObj);
    //     }
    //   });
    //   setRender(true);
    // } else {
    //   deleteMultiSensorAndDevice({ deviceIds: DeviceIds });
    //   const NewObj = selectedItems?.filter((item) => {
    //     return !DeviceIds?.includes(item.id);
    //   });
    //   setSelectedItems(NewObj);
    //   setRender(true);
    // }
  };

  const onShowShubscriptionStatus = (subscriptionStatus, title) => {
    if (subscriptionStatus === "Expired") {
      return (
        <button
          className="subscription-Expired-btn"
          style={{
            background: "#ff000030",
            padding: "1px 9px",
            color: "red",
            border: "1px solid #ff8f8f",
            borderRadius: "5px",
            position: "relative",
          }}
        >
          {title}
          <span
            style={{
              width: "10px",
              height: "10px",
              background: "red",
              position: "absolute",
              right: "-3px",
              top: "-5px",
              borderRadius: "50%",
              border: "2px solid white",
            }}
            title="Expired "
          ></span>
        </button>
      );
    } else if (subscriptionStatus === "Active") {
      return (
        <button className="subscription-btn" style={{ position: "absolute" }}>
          {title}{" "}
          <span
            style={{
              width: "10px",
              height: "10px",
              background: "#3c6500",
              position: "absolute",
              right: "-3px",
              top: "-5px",
              borderRadius: "50%",
              border: "2px solid white",
            }}
            title="Active "
          ></span>
        </button>
      );
    }
  };

  return (
    <div className="position-relative">
      <div
        className="alert-header-buttons-group"
        style={{ position: "absolute", top: "-57px", right: "20px" }}
      >
        {/* <Button
          text="Delete"
          icon={<BiTrash className="Tabs-icons" />}
          oK={() => DeviceDeleteHandler()}
          buttonStyle={
            selectedItems?.length === 0
              ? "global-btn deleteDeactive me-3 btn-padding"
              : "global-btn green-btn me-3 btn-padding"
          }
        /> */}
      </div>
      <table className="table mb-4">
        <thead>
          <tr>
            {/* <th>
              <input
                type="checkbox"
                onClick={(e) => handleSelectItem(e, currentItems, "allData")}
                value=""
                checked={
                  pageChnages
                    ? true
                    : currentItems?.length === selectedItems?.length
                    ? true
                    : false
                }
              />
            </th> */}
            <th>
              {/* <span className="table-icons">
                <BiBroadcast />
              </span> */}
              Farmer Name
            </th>
            <th>
              <span className="table-icons">
                <HiOutlineMail />
              </span>
              Email
            </th>{" "}
            <th>
              <span className="table-icons">
                <BiMobileAlt />
              </span>{" "}
              Mobile Number
            </th>
            <th>
              <span className="table-icons">
                <MdOutlineHomeWork />
              </span>
              Company Name
            </th>{" "}
            <th>
              <span className="table-icons">
                <BiBroadcast />{" "}
              </span>{" "}
              Sensors
            </th>
            <th>
              <span className="table-icons">
                <BiBuoy />
              </span>{" "}
              Devices
            </th>
            <th>
              <span className="table-icons">
                <RiLeafLine />
              </span>{" "}
              Subscription
            </th>
            <th>Manage</th>
          </tr>
        </thead>
        <tbody>
          {currentItems?.map((ele, index) => {
            let { devices, sensors, subscription } = ele;
            // let readingarameter = JSON.parse(ele.device_type);
            return (
              <tr key={ele.farmer.id}>
                {/* <td>
                    <input
                      type="checkbox"
                      onClick={(e) => handleSelectItem(e, ele.farmer)}
                      value={ele.farmer}
                      checked={
                        pageChnages
                          ? false
                          : selectedItems.includes(ele.farmer)
                          ? true
                          : false
                      }
                    />
                  </td> */}
                <td onClick={() => editDeviceItem(ele)}>
                  {ele.farmer?.first_name}&nbsp;
                  {ele?.farmer?.last_name}
                </td>
                <td>{ele?.farmer?.email}</td>
                <td>{ele?.farmer?.contact_No || <AiOutlineDash />} </td>
                <td>{ele?.farmer?.company_name || <AiOutlineDash />} </td>
                <td style={{ cursor: "pointer" }}>
                  {/* {sensors} */}
                  {ele?.sensorsInfo && (
                    <DevicePopUp Count={sensors} data={ele?.sensorsInfo} />
                  )}
                </td>
                <td style={{ cursor: "pointer" }}>
                  {ele.deviceInfo && (
                    <DevicePopUp Count={devices} data={ele?.deviceInfo} />
                  )}
                </td>
                <td>
                  {subscription !== null ? (
                    <div className="subscription-wrappers">
                      {onShowShubscriptionStatus(
                        subscription?.status,
                        subscription?.title
                      )}
                    </div>
                  ) : (
                    <i className="text-secondary">No records</i>
                  )}
                </td>
                <td>
                  <SubscriptionRecord data={ele} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {currentItems?.length === 0 && (
        <div className="d-flex flex-column justify-content-center align-items-center mb-3">
          <div style={{ fontSize: "50px", color: "#a9b6c2" }}>
            <BsInbox />
          </div>
          <p style={{ color: "#a9b6c2" }}>No Record Found.</p>
        </div>
      )}
      <div className="paginate">
        {currentItems?.length !== 0 && FarmerUser?.length >= 10 && (
          <ReactPaginate
            breakLabel="..."
            nextLabel={">"}
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel={"<"}
            renderOnZeroPageCount={null}
          />
        )}
      </div>
    </div>
  );
};

export default AllFarmers;

import React, { useState, useEffect } from "react";
import { AiOutlineAppstoreAdd, AiOutlinePlus } from "react-icons/ai";
import { BiBarcode, BiDetail, BiTrash } from "react-icons/bi";
import ReactPaginate from "react-paginate";
import Button from "../../../Component/Button";
import { GrDocumentTime, GrStatusPlaceholder } from "react-icons/gr";
import {
  getAllDeviceReadings,
  getAllSensorsReadings,
  ondeleteReadings,
} from "../../../ApisURl/ApisUrl";

import { BsInbox, BsListCheck } from "react-icons/bs";
import { MdDeviceHub } from "react-icons/md";
import AddReadings from "./ReadingsSPModal/AddReadings";

const ReadingsSuperAdmin = ({
  DeviceReadings,
  setDeviceReadings,
  filterRows,
}) => {
  const [selectedItems, setSelectedItems] = useState([]);
  // const [pageChnages , setPageChnage] = useState(false)

  const [editReading, setEditReading] = useState({});
  const [showDeviceModal, setShowDeviceModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setId] = useState();
  const [DeviceName, setDeviceName] = useState();

  const [render, setRender] = useState(false);

  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + 10;

  const currentItems =
    filterRows?.serchText?.length !== 0
      ? filterRows?.filterRow?.slice(itemOffset, endOffset) || []
      : DeviceReadings?.slice(itemOffset, endOffset) || [];

  const pageCount =
    filterRows?.serchText?.length !== 0
      ? Math.ceil(filterRows?.filterRow?.length / 10)
      : Math.ceil(DeviceReadings?.length / 10);

  const handlePageClick = (event) => {
    const newOffset =
      filterRows?.serchText?.length !== 0
        ? (event.selected * 10) % filterRows?.filterRow?.length
        : (event.selected * 10) % DeviceReadings?.length;

    setItemOffset(newOffset);
  };

  const DeviceIds = selectedItems && selectedItems?.map((item) => item.id);

  const handleSelectItem = (e, okData, DataType) => {
    if (e.target.checked === true && DataType === "allData") {
      setSelectedItems(okData);
    } else if (selectedItems?.includes(okData)) {
      const NewObj = selectedItems?.filter((item) => {
        return item.id !== okData.id;
      });
      setSelectedItems(NewObj);
    } else if (!DeviceIds.includes(okData.id) && DataType !== "allData") {
      setSelectedItems([...selectedItems, okData]);
    } else {
      setSelectedItems([]);
    }
  };

  // Fetch data from both APIs
  const fetchAllData = async () => {
    const deviceReadingsResponse = await getAllDeviceReadings();
    const sensorsReadingsResponse = await getAllSensorsReadings();

    // Check if both responses have valid readings arrays
    const deviceReadings = Array.isArray(deviceReadingsResponse?.readings)
      ? deviceReadingsResponse.readings
      : [];
    const sensorsReadings = Array.isArray(sensorsReadingsResponse?.readings)
      ? sensorsReadingsResponse.readings
      : [];

    // Merge the arrays and set the combined state
    setDeviceReadings([...deviceReadings, ...sensorsReadings]);
  };
  useEffect(() => {
    fetchAllData();
  }, [isModalOpen, render, showDeviceModal]);

  // #EDIT DEVICE
  const editDeviceItem = (editReadingData) => {
    setEditReading(editReadingData);
    setIsModalOpen(true);
  };

  // Delete readings
  const ondeleteHandlereReadings = () => {
    ondeleteReadings(DeviceIds).then((res) => {
      if (res.status === 200) {
        const NewObj = selectedItems?.filter((item) => {
          return !DeviceIds?.includes(item.id);
        });

        let NewParentData = DeviceReadings?.filter((item) => {
          return !DeviceIds?.includes(item.id);
        });
        setDeviceReadings(NewParentData);
        setSelectedItems(NewObj);
      }
    });
  };


  return (
    <div className="position-relative">
      <div
        className="alert-header-buttons-group"
        style={{ position: "absolute", top: "-57px", right: "20px" }}
      >
        <Button
          text="Delete"
          icon={<BiTrash className="Tabs-icons" />}
          oK={() => ondeleteHandlereReadings()}
          buttonStyle={
            selectedItems?.length === 0
              ? "global-btn deleteDeactive me-3 btn-padding"
              : "global-btn green-btn me-3 btn-padding"
          }
        />
      </div>
      <table className="table position-relative">
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                onClick={(e) => handleSelectItem(e, currentItems, "allData")}
                value=""
                checked={
                  currentItems?.length === 0
                    ? false
                    : currentItems?.length === selectedItems?.length
                      ? true
                      : false
                }
              />
            </th>

            <th>Sr No.</th>
            <th>Reading Name</th>
            <th>Device Type</th>
            <th>Reading Icon</th>
          </tr>
        </thead>
        <tbody>
          {currentItems?.map((ele, index) => {
            const srNo = index + 1 + itemOffset;
            let { icon, reading, type } = ele;
            return (
              <tr key={ele.id}>
                <td style={{ width: "60px" }}>
                  <input
                    type="checkbox"
                    onClick={(e) => handleSelectItem(e, ele)}
                    value={ele}
                    checked={selectedItems.includes(ele) ? true : false}
                  />
                </td>
                <td>{srNo}</td>
                <td
                  onClick={() => editDeviceItem(ele)}
                  className="linkable"
                  style={{ cursor: "pointer" }}
                >
                  {reading}
                </td>
                <td>{type}</td>
                <td>
                  {icon ? (
                    <img
                      src={`${process.env.REACT_APP_AGROIOT_BASE_URL}/${icon}`}
                      alt="iconImage"
                      width={100}
                      height={80}
                    />
                  ) : (
                    <i className="text-secondary">No Icon present</i>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {currentItems?.length === 0 && (
        <div className="d-flex flex-column justify-content-center align-items-center mb-3">
          <div style={{ fontSize: "50px", color: "#a9b6c2" }}>
            <BsInbox />
          </div>
          <p style={{ color: "#a9b6c2" }}>No Record Found.</p>
        </div>
      )}

      <div className="paginate">
        {currentItems?.length !== 0 && DeviceReadings?.length >= 10 && (
          <ReactPaginate
            breakLabel="..."
            nextLabel={">"}
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel={"<"}
            renderOnZeroPageCount={null}
          />
        )}
      </div>
      <div className="global-button add-btn pb-4 text-end">
        <Button
          text="Add Reading"
          buttonStyle="global-btn primary-btn btn-padding"
          righIcon={<AiOutlinePlus />}
          oK={() => {
            setEditReading("");
            setIsModalOpen(true);
          }}
        />
      </div>
      {/* <RegisterSensor
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        editEndDeviceItem={editEndDeviceItem}
        setEditEndDeviceItem={setEditEndDeviceItem}
      /> */}

      <AddReadings
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        editRecord={editReading}
        setEditRecord={setEditReading}
      />
    </div>
  );
};

export default ReadingsSuperAdmin;

import { createSlice } from "@reduxjs/toolkit";

const selectSlice = createSlice({
  name: "selectTableValue",
  initialState: {
    userCurrentPackage: localStorage.getItem("userPlan")
      ? JSON.parse(localStorage.getItem("userPlan"))
      : {},
  },
  reducers: {
    userPlan: (state, action) => {
      state.userCurrentPackage = action.payload;
    },
  },
});

export default selectSlice.reducer;
export const { userPlan } = selectSlice.actions;

export const UserPlanData = (state) => state.userPlan.userCurrentPackage;

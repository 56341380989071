import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { lazy, Suspense } from "react";
import PrivateRoute from "./PrivateRoute";
import PageNotFound from "../Page/PageNotFound";
import { useSelector } from "react-redux";
import SettingsSuper from "../SupedAdmin/pages/Settings/SettingsSuper";
import IndexFarmers from "../SupedAdmin/pages/Farmers/IndexFarmers";
// import Loader from "../Assets/Images/loader.svg";
import PackagesIndex from "../SupedAdmin/pages/packages/PackagesIndex";
import Currnetpackage from "../SupedAdmin/pages/CurrentPackage/Currnetpackage";
import SubscriptionHistory from "../SupedAdmin/pages/UserSubscription/SubscriptionHistory";
import Tickets from "../SupedAdmin/pages/Tickets/Tickets";
import EmailVarification from "../Page/EmailVarification/EmailVarification";
import AlertTemplateIndex from "../SupedAdmin/pages/AlertTemplate/AlertTemplateIndex";
const SuperAdminDashboard = lazy(() =>
  import("../SupedAdmin/pages/SuerAdminDashboard/SADashbaord")
);
const Layout = lazy(() => import("../Layout/Main/index"));
const Login = lazy(() => import("../Page/Login/Index"));
const Dashboard = lazy(() => import("../Page/Dashboard/Index"));
const Farm = lazy(() => import("../Page/Farm/Index"));
const AllFarms = lazy(() => import("../Page/Farm/AllFarm/AllFarm"));
const AlertsPage = lazy(() => import("../Page/Alerts/Index"));
const AutomatePage = lazy(() => import("../Page/Automate/Index"));
const SettingPage = lazy(() => import("../Page/Settings/Index"));
const ForgotPassword = lazy(() => import("../Page/ForgetPage/Index"));
const ResetPassword = lazy(() => import("../Page/ResetPassword/ResetPassword"));
const SignUpPage = lazy(() => import("../Page/Login/SignUp/SignUpPage"));
const Card = lazy(() => import("../SupedAdmin/Component/Card"));

const Index = () => {
  const { AuthUser } = useSelector((state) => state);
  let user;
  if (AuthUser?.user?.role === "admin") {
    user = "admin";
  } else if (AuthUser?.user?.role === "super-admin") {
    user = "super-admin";
  }
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback={<div>Loading</div>}>
              <Login />
            </Suspense>
          }
        />
        <Route element={<PrivateRoute />}>
          {user === "admin" ? (
            <Route
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <Layout />
                </Suspense>
              }
            >
              <Route
                path="/Dashboard"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <Dashboard />
                  </Suspense>
                }
              />
              <Route
                path="/Farm"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <Farm />
                  </Suspense>
                }
              />
              <Route
                path="/Farm/:groupName/:id"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <AllFarms />
                  </Suspense>
                }
              />
              <Route
                path="/Alerts"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <AlertsPage />
                  </Suspense>
                }
              />
              <Route
                path="/Automate"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <AutomatePage />
                  </Suspense>
                }
              />
              <Route
                path="/Settings"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <SettingPage />
                  </Suspense>
                }
              />
            </Route>
          ) : (
            <Route
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <Layout />
                </Suspense>
              }
            >
              <Route
                path="/Dashboard"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <SuperAdminDashboard />
                  </Suspense>
                }
              />
              <Route
                path="/Farmers"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <IndexFarmers />
                  </Suspense>
                }
              />
              <Route
                path="/Alerts"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <AlertTemplateIndex />
                  </Suspense>
                }
              />
              <Route
                path="/Plans"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <PackagesIndex />
                  </Suspense>
                }
              />

              <Route
                path="/tickets"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <Tickets />
                  </Suspense>
                }
              />

              <Route
                path="/Settings"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <SettingsSuper />
                  </Suspense>
                }
              />

              <Route
                path="/currentplan"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <Currnetpackage />
                  </Suspense>
                }
              />

              <Route
                path="/plan_history"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <SubscriptionHistory />
                  </Suspense>
                }
              />
            </Route>
          )}
        </Route>

        <Route
          path="/ForgotPassword"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <ForgotPassword />
            </Suspense>
          }
        />
        <Route
          path={`/api/users/reset-password/:id`}
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <ResetPassword />
            </Suspense>
          }
        />
        <Route
          path="/SignUp"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <SignUpPage />
            </Suspense>
          }
        />

        <Route
          path="/users/email_verification/:id"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <EmailVarification />
            </Suspense>
          }
        />
        <Route path="/*" element={<PageNotFound />} />

        {/* <Route
          path="/laoding"
          element={
            <div className="Loader-wraper">
              <img
                src={Loader}
                alt="loader"
                style={{ width: "200px", height: "350px" }}
              />
            </div>
          }
        /> */}

        <Route
          path="/card"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <Card />
            </Suspense>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Index;

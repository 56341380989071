import React from "react";
import { DownOutlined, UserOutlined } from "@ant-design/icons";

import { Button, Dropdown, Space, Tooltip, message } from "antd";
import { FiChevronDown } from "react-icons/fi";
import { MdHistory } from "react-icons/md";
import { HiOutlineCurrencyDollar } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userPlan } from "../../Store/Slices/CurrentPlan";

const SubscriptionRecord = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const items = [
    {
      label: "Current subscription",
      key: "1",
      icon: <HiOutlineCurrencyDollar />,
      // disabled: data.subscription === null ? true : false,
    },
    {
      label: "Subscription History",
      key: "2",
      icon: <MdHistory />,
    },
    // {
    //   label: "3rd menu item",
    //   key: "3",
    //   icon: <UserOutlined />,
    //   danger: true,
    // },
    // {
    //   label: "4rd menu item",
    //   key: "4",
    //   icon: <UserOutlined />,
    //   danger: true,
    //   disabled: true,
    // },
  ];

  const handleMenuClick = (e) => {
    // if (data.subscription === null) {
    // }
    dispatch(userPlan(data));
    localStorage.setItem("userPlan", JSON.stringify(data));
    if (e.key === "1") {
      navigate("/currentplan");
    } else {
      navigate("/plan_history");
      // /plan_history
    }
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };
  return (
    <Dropdown
      menu={menuProps}
      placement="bottomRight"
      arrow={{
        pointAtCenter: true,
      }}
    >
      <Button>
        <Space>
          Subscription
          <FiChevronDown />
        </Space>
      </Button>
    </Dropdown>
  );
};

export default SubscriptionRecord;

import { Modal, Select } from "antd";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useEffect } from "react";
import { BiPencil } from "react-icons/bi";
import {
  getAddReadingsInAdmin,
  getEditReadingsInAdmin,
  getUnitReadings,
} from "../../../../ApisURl/ApisUrl";
import Button from "../../../../Component/Button";
const AddReadings = ({
  isModalOpen,
  setIsModalOpen,
  editRecord,
  setEditRecord,
}) => {
  const [editable, setEditable] = useState(false);
  const [file, setFile] = useState();
  const [options, setOptions] = useState([]);

  const handleCancel = () => {
    setIsModalOpen(false);
    setEditable(false);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  function handleChange(e) {
    setFile({
      image: URL.createObjectURL(e.target.files[0]),
      FileName: e.target.files[0],
      isImageUpload: true
    });
  }


  const getValues = (values) => {
    let data = {};






    if (editRecord.reading) {



      if (values.type === "device") {
        if (file?.isImageUpload === true) {
          data = {
            reading: values.reading,
            icon: file.FileName,
            type: values.type,
          };
        } else {
          data = {
            reading: values.reading,
            type: values.type,
          };
        }
      } else {
        if (file?.isImageUpload === true) {
          data = {
            ...values,
            icon: file.FileName,

          };
        } else {
          data = {
            ...values,
          };
        }

      }
      getEditReadingsInAdmin({ id: editRecord.id, data: data }).then((res) => {
        setIsModalOpen(false);
        setEditable(false);
      });
    } else {
      if (values.type === "device") {
        data = {
          reading: values.reading,
          icon: file.FileName,
          type: values.type,
        };
      } else {
        data = {
          ...values,
          icon: file.FileName,
        };
      }
      getAddReadingsInAdmin(data).then((res) => {
        setIsModalOpen(false);
        setEditable(false);
      });
    }
  };


  console.log(file?.isImageUpload, "file ");


  useEffect(() => {

    const convertImageUrlToFile = async (imageUrl) => {
      try {
        // Fetch the image data as a blob
        const response = await fetch(imageUrl);
        const blob = await response.blob();

        // Create a File object with a unique name
        const fileName = imageUrl.substring(imageUrl.lastIndexOf('/') + 1);
        const fileObject = new File([blob], fileName, { type: blob.type });

        return fileObject

        // setFileObject(file);
      } catch (error) {
        console.error('Error converting image URL to File:', error.message);
      }
    };

    convertImageUrlToFile(`${`${process.env.REACT_APP_AGROIOT_BASE_URL}/${editRecord.icon}`} `).then((res) => {
      localStorage.setItem("editPreviousFileName", res.name)
      setFile((prev) => ({
        ...prev,
        FileName: res,
        image: URL.createObjectURL(res),
        isImageUpload: false
      }));
    });

    getUnitReadings("")
      .then((res) => {
        setOptions(res.units);
      })
      .catch((error) => {
        console.log("errpr", error);
      });
  }, [editRecord]);

  // Function to handle user input and fetch options
  const handleSearch = async (searchText) => {
    try {
      getUnitReadings(searchText)
        .then((res) => {
          setOptions(res.units);
        })
        .catch((error) => {
          console.log("errpr", error);
        });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };




  return (
    <Modal
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      destroyOnClose
      closable={false}
      footer={false}
      width={550}
      className=""
    >
      <Formik
        initialValues={{
          reading: editRecord ? editRecord.reading : "",
          type: editRecord ? editRecord.type : "device",
          unit: editRecord ? editRecord?.unit?.unit : "",
        }}
        validate={(values) => {
          const errors = {};
          if (!values.reading) {
            errors.reading = "Required";
          } else if (values.reading?.length > 50) {
            errors.reading = " reading name must be 50 characters or less.";
          }


          return errors;
        }}
        onSubmit={(values, { resetForm }) => {
          getValues(values, resetForm);
        }}
        className="group-create-modal"
      >
        {({ resetForm, values, setFieldValue, touched, errors }) => (
          <Form>
            <div className="form_wraper">
              <div
                className=""
                style={{
                  borderBottom: "1px solid #D9D4D4",
                  padding: "0 10px ",
                }}
              >
                <div className="d-flex  pb-2">
                  <div className=" d-flex align-items-center ms-2 ">
                    <span
                      className=""
                      style={{
                        width: "80px",
                        color: "black",
                        fontWeight: "700",
                      }}
                    >
                      Name
                    </span>
                  </div>

                  <div className="d-flex w-50 flex-column">
                    <Field
                      type="text"
                      name="reading"
                      placeholder="Reading Name"
                      className={`form-control FormikInput ${touched.reading && errors.reading ? "invalid-field" : ""
                        } `}
                      disabled={editRecord && !editable}
                    />
                  </div>
                </div>
                <ErrorMessage
                  name="reading"
                  className="Errormessage mb-2"
                  component="div"
                  style={{ marginLeft: "95px" }}
                />
              </div>

              <div
                style={{
                  borderBottom: "1px solid rgba(0,0 ,0 ,0.1)",
                  padding: "20px 10px 20px 20px",
                }}
              >
                <div role="group" aria-labelledby="my-radio-group">
                  <label htmlFor="nwk_key" className=" my-2 me-2  fw-bold">
                    Device Type
                  </label>
                  <label className="me-4 pt-1">
                    <Field
                      type="radio"
                      name="type"
                      autoComplete="off"
                      value="device"
                      className="mx-2"
                      disabled={editRecord && true}
                    />
                    Device
                  </label>
                  <label className="me-4  pt-1">
                    <Field
                      type="radio"
                      name="type"
                      value="sensor"
                      autoComplete="off"
                      className="mx-2"
                      disabled={editRecord && true}
                    />
                    Sensor
                  </label>
                </div>
                {values.type === "sensor" && (
                  <div
                    role="group"
                    aria-labelledby="my-radio-group"
                    className="pt-2"
                  >
                    <label htmlFor="unit" className="my-2 me-2 fw-bold">
                      Unit Field
                    </label>
                    <Select
                      showSearch // Enable search functionality
                      placeholder="Select unit"
                      name="unit"
                      value={values.unit || ""}
                      style={{ width: "180px" }}
                      disabled={editRecord && !editable}
                      onSearch={handleSearch}
                      onChange={(value) => setFieldValue("unit", value)}
                    >
                      {options?.map((option) => (
                        <option key={option.id} value={option.unit}>
                          {option.unit}
                        </option>
                      ))}
                    </Select>
                  </div>
                )}
              </div>

              <div
                style={{
                  borderBottom: "1px solid #D9D4D4",
                  padding: "0 20px ",
                }}
                className="py-2"
              >
                <div className="d-flex py-3 ">
                  <div className=" d-flex align-items-center">
                    <span
                      className=""
                      style={{
                        color: "black",
                        fontWeight: "700",
                        width: "100px",
                      }}
                    >
                      Upload Icon
                    </span>
                  </div>

                  <div className="d-flex w-75 flex-column upload-icon-image position-relative">
                    <input
                      type="file"
                      className="form-control FormikInput"
                      id="image-upload"
                      accept="image/*"
                      onChange={handleChange}
                      disabled={editRecord && !editable}
                    />
                    {editRecord.icon && (
                      <span
                        className="position-absolute"
                        style={
                          editRecord && !editable
                            ? {
                              top: "15px",
                              left: "100px",
                              background: "#F5F5F5",
                              color: "gray",
                            }
                            : {
                              top: "15px",
                              left: "100px",
                              background: "white",
                              color: "black",
                            }
                        }
                      >
                        {file?.FileName?.name}
                      </span>
                    )}
                  </div>

                </div>
              </div>

              {editRecord ? (
                <div className="text-end me-3 pt-2">
                  {editRecord && editable ? (
                    <>
                      <Button
                        text="Cancel"
                        oK={handleCancel}
                        buttonType="button"
                        buttonStyle="global-btn secondary-btn px-2 me-2"
                      />
                      <Button
                        text="Update"
                        buttonType="submit"
                        buttonStyle="global-btn primary-btn px-2"
                      />
                    </>
                  ) : (
                    <Button
                      text="Edit"
                      buttonType="button"
                      icon={<BiPencil className="me-1" />}
                      buttonStyle="global-btn primary-btn me-3 px-4"
                      oK={() => setEditable(true)}
                    />
                  )}
                </div>
              ) : (
                <div className="text-end me-3 pt-2">
                  <Button
                    text="Cancel"
                    oK={handleCancel}
                    buttonType="button"
                    buttonStyle="global-btn secondary-btn px-2 me-2"
                  />
                  <Button
                    text="Create"
                    buttonType="submit"
                    buttonStyle="global-btn primary-btn px-2"
                  />
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddReadings;

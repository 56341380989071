import { Modal, Select, TimePicker } from "antd";
import { ErrorMessage, Field, Formik, Form } from "formik";
import moment from "moment";
import React, { useState } from "react";
import { BiPencil } from "react-icons/bi";
import { createTemplate, editTemplate } from "../../../../ApisURl/ApisUrl";
import Button from "../../../../Component/Button";
import ParameterRule from "./Parameter";

const AddAlertTemplateSuper = ({ isModalOpen, setIsModalOpen, editRecord }) => {
  const [editable, setEditable] = useState(false);

  const [isAlertFormOpen, setIsAlertFormOpen] = useState(false);
  const handleCancel = () => {
    setIsModalOpen(false);
    setEditable(false);
  };

  const formValues = (values) => {
    delete values.pest
    delete values.observation_time
    if (editRecord) {
      if (values.repeat_after !== "never") {
        values.repeat_after = values.time;
        delete values.time;
      } else {
        delete values.time;
      }
      editTemplate({ id: editRecord.template.id, body: values }).then((res) => {
        setIsModalOpen(false);
        setEditable(false);
      });
    } else {
      if (values.repeat_after !== "never") {
        values.repeat_after = values.time;
        delete values.time;
      } else {
        delete values.time;
      }


      createTemplate(values).then((res) => {
        setIsModalOpen(false);
        setEditable(false);
      });
    }
  };

  return (
    <Modal
      open={isModalOpen}
      onCancel={handleCancel}
      destroyOnClose
      closable={false}
      footer={false}
      width={650}
      className=""
    >
      <Formik
        enableReinitialize={true}
        initialValues={{
          name: editRecord ? editRecord?.template?.name : "",
          template_para: editRecord
            ? editRecord?.template?.templateconditions
            : [{ parameter: "", condition: "", value: "" }],
          message: editRecord ? editRecord?.template?.message : "",
          inform_via: editRecord
            ? editRecord?.template?.inform_via?.split(",")
            : ["SMS"],
          repeat_after: editRecord
            ? editRecord?.template?.repeat_after
            : "never",
          end_repeat_after: editRecord
            ? editRecord?.template?.end_repeat_after
            : 1,
          time:
            editRecord?.template?.repeat_after === "never"
              ? "1"
              : editRecord?.template?.repeat_after,
          alert_from: editRecord?.template?.alert_from
            ? editRecord.template.alert_from
            : "",
          alert_to: editRecord?.template?.alert_to
            ? editRecord?.template?.alert_to
            : "",
          pest: "",
          observation_time: "",
        }}
        validate={(values, touched) => {
          const errors = {};

          if (!values.name) {
            errors.name = "Required";
          } else if (values.name.length > 50) {
            errors.name = "Alert template name must be 50 characters or less";
          }

          values.template_para.forEach((item, index) => {
            if (!item.parameter) {
              errors.parameter = errors.parameter || [];
              errors.parameter[index] = {
                parameter: "Required",
              };
            }
            if (!item.condition) {
              errors.condition = errors.condition || [];
              errors.condition[index] = {
                condition: "Required",
              };
            }
            if (!item.value) {
              errors.value = errors.value || [];
              errors.value[index] = {
                value: "Parameter count is required",
              };
            } else if (item.value < 0) {
              errors.value = errors.value || [];
              errors.value[index] = {
                value: "Parameter count is should be above zero",
              };
            }

            // Additional validations
            if (
              item.parameter === "Temperature" ||
              item.parameter === "Humidity"
            ) {
              if (item.value < 0 || item.value > 100) {
                errors.value = errors.value || [];
                errors.value[index] = {
                  value:
                    "Invalid count for temperature or humidity. Count should be between 0 and 100",
                };
              }
            }

            if (item.parameter === "Sun Light") {
              if (/^\d{0,5}$/.test(item.value)) {
                return null;
              } else {
                errors.value = errors.value || [];
                errors.value[index] = {
                  value: "Sun Light value should be below 5 digits",
                };
              }
            }
          });

          if (!values.message) {
            errors.message = "Required";
          }
          if (!values.inform_via) {
            errors.inform_via = "Required";
          }
          if (!values.repeat_after) {
            errors.repeat_after = "Required";
          }

          if (values.time > 59) {
            errors.time = "minute should be in below 59.";
          }
          if (!values.alert_from) {
            errors.alert_from = "Required";
          }
          if (!values.alert_to) {
            errors.alert_to = "Required";
          }

          // if (!values.pest) {
          //   errors.pest = "Required";
          // }

          // const pattern = /^([01]\d|2[0-3]):([0-5]\d)$/;
          // if (!values.observation_time) {
          //   errors.observation_time = "Required";
          // }
          // if (!pattern.test(values.observation_time)) {
          //   errors.observation_time = "Invalid time format (HH:MM)";
          // }

          return errors;
        }}
        onSubmit={(values, { resetForm }) => {
          // if (values.repeat_after !== "never") {
          //   values["repeat_after"] = moment(values.repeat_after).format("LT");
          //   values.repeat_after = values.time;
          //   delete values.time;
          // }
          if (values.repeat_after === "never") {
            delete values.end_repeat_after;
          }

          formValues(values);
        }}
        className="group-create-modal"
      >
        {({ setFieldValue, values, errors, touched, setValues }) => (
          <Form>
            <div className="form_wraper h-wraper-65">
              {/* ========================alert name======================== */}
              <div
                className="py-2 "
                style={{ borderBottom: "1px solid #D9D4D4" }}
              >
                <label htmlFor="" className="d-flex align-items-center">
                  <span
                    className="ms-3"
                    style={{
                      width: "100px",
                      color: "black",
                      fontWeight: "700",
                    }}
                  >
                    Alert Name{" "}
                  </span>
                  <Field
                    name="name"
                    type="text"
                    disabled={editRecord && !editable}
                    autoComplete="off"
                    placeholder="Alert Name"
                    className={`form-control FormikInput ${touched.name && errors.name ? "invalid-field" : ""
                      }`}
                    style={{ width: "200px" }}
                  />
                </label>
                <ErrorMessage
                  name="name"
                  component="div"
                  className="text-danger"
                  style={{
                    marginLeft: "120px",
                  }}
                />
              </div>

              {/* ======================== Rule ======================== */}
              <ParameterRule
                values={values}
                setFieldValue={setFieldValue}
                touched={touched}
                editRecord={editRecord}
                editable={editable}
                errors={errors}
                setValues={setValues}
              />

              {/*  ===================== disease and obsevation Time ============== */}
              <div
                className="py-3 d-flex"
                style={{ borderBottom: "1px solid #D9D4D4" }}
              >
                <div className="d-flex flex-column">
                  <div className="d-flex  align-items-center">
                    <span
                      className="ms-3 me-1"
                      style={{
                        width: "311px",
                        color: "black",
                        fontWeight: "700",
                      }}
                    >
                      Name of Pest or Disease
                    </span>
                    <Field
                      type="text"
                      name="pest"
                      value={values.pest}
                      placeholder="Enter disease here"
                      className={`form-control FormikInput  ${touched.pest && errors.pest ? "invalid-field" : ""
                        }`}
                      disabled={editRecord && !editable}
                      autoComplete="off"
                      style={{ width: "80%" }}
                    />
                  </div>

                  <ErrorMessage
                    name="pest"
                    component="div"
                    className="text-danger Errormessage"
                    style={{ marginLeft: "192px" }}
                  />
                </div>
                {/* time */}
                <div className="d-flex  flex-column">
                  <div className="d-flex  align-items-center">
                    <span
                      className="ms-2 me-1"
                      style={{
                        width: "120px",
                        color: "black",
                        fontWeight: "700",
                      }}
                    >
                      Observation Time
                    </span>

                    <Field
                      type="text"
                      name="observation_time"
                      value={values.observation_time}
                      placeholder="HH:MM"
                      className={`form-control FormikInput me-5 ${touched.observation_time && errors.observation_time
                        ? "invalid-field"
                        : ""
                        }`}
                      disabled={editRecord && !editable}
                      autoComplete="off"
                      style={{ width: "30%" }}
                    />
                  </div>

                  <ErrorMessage
                    name="observation_time"
                    component="div"
                    className="text-danger Errormessage"
                    style={{ marginLeft: "140px" }}
                  />
                </div>
              </div>

              {/* ===================== Action message ============== */}
              <div
                className="py-3"
                style={{ borderBottom: "1px solid #D9D4D4" }}
              >
                <label
                  htmlFor="actionMessage"
                  className="d-flex align-items-center justify-space-between"
                >
                  <span
                    className=" ms-2  "
                    style={{
                      width: "140px",
                      color: "black",
                      fontWeight: "700",
                    }}
                  >
                    Action Message{" "}
                  </span>
                  <Field
                    as="textarea"
                    row="10"
                    disabled={editRecord && !editable}
                    autoComplete="off"
                    name="message"
                    className={`form-control FormikInput me-2 ${touched.message && errors.message ? "invalid-field" : ""
                      }`}
                    placeholder="Enter Your Message Here"
                    style={{ width: "520px" }}
                  />
                </label>
                <ErrorMessage
                  name="message"
                  component="div"
                  className="text-danger Errormessage"
                  style={{ marginLeft: " 140px" }}
                />
              </div>

              {/* ================================ inform Via ======================= */}

              <div className="inform d-flex align-items-center">
                <span
                  className=""
                  style={{
                    width: "100px",
                    color: "black",
                    fontWeight: "700",
                  }}
                >
                  Inform Via
                </span>
                <Field
                  type="checkbox"
                  name="inform_via"
                  value="SMS"
                  disabled={editRecord && !editable}
                  autoComplete="off"
                />
                &nbsp;&nbsp;SMS &nbsp;&nbsp;
                <Field
                  type="checkbox"
                  name="inform_via"
                  value="Email"
                  disabled={editRecord && !editable}
                  autoComplete="off"
                />
                &nbsp; &nbsp;Email &nbsp;&nbsp;
                <Field
                  type="checkbox"
                  name="inform_via"
                  disabled={editRecord && !editable}
                  value="Notification"
                />
                &nbsp;&nbsp; Notification &nbsp; &nbsp;
                <ErrorMessage
                  name="inform_via"
                  component="div"
                  className="text-danger Errormessage"
                />
              </div>

              {/* ================================ Repeat Via ======================= */}
              <div className="inform">
                <div className=" d-flex align-items-center">
                  <span
                    className=""
                    style={{
                      width: "100px",
                      color: "black",
                      fontWeight: "700",
                    }}
                  >
                    Repeat After
                  </span>
                  <div
                    role="group"
                    aria-labelledby="my-radio-group"
                    className="d-flex align-items-center "
                  >
                    <label className="d-flex mx-2  align-items-center">
                      <Field
                        type="radio"
                        autoComplete="off"
                        name="repeat_after"
                        disabled={editRecord && !editable}
                        value="repeat"
                        style={{ width: "15px" }}
                        checked={values.repeat_after !== "never" ? true : false}
                      />
                      <Field
                        name="time"
                        autoComplete="off"
                        id="time"
                        value={values.time || ""}
                        placeholder="Minutes"
                        // value={values.repeat_after}
                        className={`form-control FormikInput mx-2 ${touched.time && errors.time ? "invalid-field" : ""
                          }`}
                        style={{ width: "100px !important" }}
                        disabled={
                          !editRecord && values.repeat_after === "never"
                            ? true
                            : editRecord && values.repeat_after === "never"
                              ? true
                              : editRecord
                                ? !editable
                                : false
                        }
                        onChange={(e) => {
                          let Value = e.target.value;
                          if (/^\d{0,2}$/.test(Value) && Value < 60) {
                            setFieldValue("time", Value);
                          }
                        }}
                      />
                      Minutes.
                    </label>
                    <label className=" mx-3">
                      <Field
                        type="radio"
                        name="repeat_after"
                        value="never"
                        disabled={editRecord && !editable}
                      />
                      &nbsp;&nbsp; Never Repeat
                    </label>
                  </div>
                </div>

                {values.repeat_after !== "never" && (
                  <div
                    className="py-2 "
                  // style={{ borderBottom: "1px solid #D9D4D4" }}
                  >
                    <label htmlFor="" className="d-flex align-items-center">
                      <span
                        className=""
                        style={{
                          width: "140px",
                          color: "black",
                          fontWeight: "700",
                        }}
                      >
                        Repeat End After
                      </span>
                      <Field
                        name="end_repeat_after"
                        type="text"
                        autoComplete="off"
                        disabled={editRecord && !editable}
                        value={values.end_repeat_after}
                        placeholder="3"
                        className={`form-control FormikInput ${touched.end_repeat_after && errors.end_repeat_after
                          ? "invalid-field"
                          : ""
                          }`}
                        style={{ width: "80px" }}
                        onChange={(e) =>
                          setFieldValue(
                            "end_repeat_after",
                            e.target.value.replace(/[^\d]{0,99}/g, "")
                          )
                        }
                      />
                      &nbsp; Times
                    </label>

                    {/* <ErrorMessage name="name" component="div" /> */}
                  </div>
                )}
              </div>

              {/* alert timeStamp */}
              <div
                className="mb-3 px-3 py-3 d-flex  "
                style={{ borderBottom: "1px solid #D9D4D4" }}
              >
                <label
                  htmlFor="Alert  From"
                  className="d-flex align-items-center me-3"
                >
                  <span
                    className="w-100"
                    style={{
                      color: "black",
                      fontWeight: "700",
                      width: "100px",
                    }}
                  >
                    {" "}
                    Alert From
                  </span>

                  <Field name="alert_from">
                    {({ field, form }) => (
                      <TimePicker
                        {...field}
                        open={isAlertFormOpen}
                        id="alert_form"
                        disabled={editRecord && !editable}
                        popupClassName="timepicker"
                        status={
                          form.touched.alert_from && form.errors.alert_from
                            ? "error"
                            : ""
                        }
                        use12Hours
                        value={field.value ? moment(field.value) : null}
                        className="form-control FormikInput w-100"
                        style={{ width: "120px !important" }}
                        format="h:mm A"


                        onChange={(time) => {
                          form.setFieldValue(field.name, time ? time : null);
                        }}
                        onSelect={(value) => {
                          if (value) {
                            const timeString = value ? value : "";
                            setFieldValue("alert_from", timeString);
                          } else {
                            setFieldValue("alert_from", null);
                          }
                        }}
                        onOpenChange={() =>
                          setIsAlertFormOpen(!isAlertFormOpen)
                        }
                      />
                    )}
                  </Field>
                </label>

                <label
                  htmlFor="Alert  From "
                  className="d-flex align-items-center"
                >
                  <span
                    className="w-25"
                    style={{ color: "black", fontWeight: "700" }}
                  >
                    To
                  </span>
                  <Field name="alert_to">
                    {({ field, form }) => (
                      <TimePicker
                        id="alert_to"
                        {...field}
                        popupClassName="timepicker"
                        status={
                          form.touched.alert_to && form.errors.alert_to
                            ? "error"
                            : ""
                        }
                        disabled={editRecord && !editable}
                        use12Hours
                        value={field.value ? moment(field.value) : null} // Use the value prop instead of defaultValue
                        className="form-control FormikInput w-100"
                        style={{ width: "120px !important" }}
                        format="h:mm A"
                        onChange={(time) => {
                          form.setFieldValue(field.name, time ? time : null);
                        }}
                        onSelect={(value) => {
                          if (value) {
                            const timeString = value ? value : "";
                            form.setFieldValue("alert_to", timeString);
                          } else {
                            form.setFieldValue("alert_to", null);
                          }
                        }}
                        onOpenChange={false}
                      />
                    )}
                  </Field>
                </label>
                <ErrorMessage name="afterTo" component="div" />
              </div>

              {/* ================== next Button ============================= */}
              {editRecord ? (
                <div className="form-modal-btn text-end pt-3 pb-2">
                  {editable && editRecord !== 0 ? (
                    <>
                      <Button
                        text="Cancel"
                        buttonType="button"
                        buttonStyle="global-btn secondary-btn me-3 px-3"
                        oK={() => {
                          setIsModalOpen(false);
                          setEditable(false);
                        }}
                      />
                      <Button
                        text="Update"
                        buttonType="Submit"
                        buttonStyle="global-btn primary-btn px-3"
                      />
                    </>
                  ) : (
                    <Button
                      text="Edit"
                      buttonType="button"
                      icon={<BiPencil className="me-1" />}
                      buttonStyle="global-btn primary-btn me-3 px-4"
                      oK={() => {
                        setEditable(true);
                      }}
                    />
                  )}
                </div>
              ) : (
                <div className="form-modal-btn  pt-3 text-end">
                  <Button
                    text="Cancel"
                    buttonType="button"
                    buttonStyle="global-btn secondary-btn me-3 "
                    oK={() => {
                      // setEditAlertTemplateItem(true);
                      setIsModalOpen(false);
                    }}
                  />
                  <Button
                    text="Create"
                    buttonType="submit"
                    buttonStyle="global-btn primary-btn"
                  />
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddAlertTemplateSuper;

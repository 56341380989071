import React, { useState } from "react";
import Serchbar from "../../../Component/Serchbar/Serchbar";
import PackageTable from "./PackageTable";

const PackagesIndex = () => {
  const [pageChnages, setPageChnage] = useState(false);
  const [allPackages, setAllPackages] = useState([]);
  const [filterRows, setFilterRows] = useState({
    filterRow: [],
    serchText: "",
  });
  function performSearch(regex) {
    return allPackages?.filter((item) => regex.test(item?.title));
  }

  function handleSearch(newSearchTerm) {
    const regex = new RegExp(newSearchTerm, "i");
    const result = performSearch(regex);
    setFilterRows({
      ...filterRows,
      filterRow: result,
      serchText: newSearchTerm,
    });
  }

  return (
    <div className="Subscription-plans-section">
      <div className="setting-section-header d-flex justify-content-between align-items-center">
        <h1 className="title mt-2 d-flex flex-column" style={{ gap: "15px" }}>
          All Packages
          {/* <span className=" sub-title ">Product Management</span> */}
        </h1>
        <Serchbar onSearch={handleSearch} />
      </div>

      <div className="packages-table-section">
        <div className="super-admin-packages">
          <PackageTable
            allPackages={allPackages}
            setAllPackages={setAllPackages}
            pageChnages={pageChnages}
            filterRows={filterRows}
            setPageChnage={setPageChnage}
          />
        </div>
      </div>
    </div>
  );
};

export default PackagesIndex;

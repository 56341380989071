import React from "react";

const Tickets = () => {
  return (
    <div className="ticket-section">
      <div className="setting-section-header d-flex justify-content-between align-items-center">
        <h1 className="title mt-2 d-flex flex-column" style={{ gap: "15px" }}>
          Tickets
        </h1>
      </div>
    </div>
  );
};

export default Tickets;

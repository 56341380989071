import React, { useState, useEffect } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { BiBroadcast, BiTrash } from "react-icons/bi";
import { BsInbox } from "react-icons/bs";
import { HiOutlineMail } from "react-icons/hi";
import { MdOutlineDescription } from "react-icons/md";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import ReactPaginate from "react-paginate";
import { deletePackage, getAllPackages } from "../../../ApisURl/ApisUrl";
import Button from "../../../Component/Button";
import CreatPackages from "./packagesModal/CreatPackages";

const PackageTable = ({
  allPackages,
  setAllPackages,
  filterRows,
  pageChnages,
  setPageChnage,
}) => {
  const [selectedItems, setSelectedItems] = useState([]);

  const [editDevicesItem, setEditDevicesItem] = useState();

  const [showDeviceModal, setShowDeviceModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [render, setRender] = useState(false);

  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + 10;

  const currentItems =
    filterRows?.serchText?.length !== 0
      ? filterRows?.filterRow?.slice(itemOffset, endOffset) || []
      : allPackages?.slice(itemOffset, endOffset) || [];

  const pageCount =
    filterRows?.serchText?.length !== 0
      ? Math.ceil(filterRows?.filterRow?.length / 10)
      : Math.ceil(allPackages?.length / 10);

  const handlePageClick = (event) => {
    const newOffset =
      filterRows?.serchText?.length !== 0
        ? (event.selected * 10) % filterRows?.filterRow?.length
        : (event.selected * 10) % allPackages?.length;

    setItemOffset(newOffset);
    setPageChnage(true);
  };

  const onChange = () => {};

  const DeviceIds = selectedItems && selectedItems?.map((item) => item.id);

  const handleSelectItem = (e, okData, DataType) => {
    if (e.target.checked === true && DataType === "allData") {
      setSelectedItems(okData);
      setPageChnage(false);
    } else if (selectedItems?.includes(okData)) {
      const NewObj = selectedItems?.filter((item) => {
        return item.id !== okData.id;
      });
      setSelectedItems(NewObj);
    } else if (!DeviceIds?.includes(okData.id) && DataType !== "allData") {
      setSelectedItems([...selectedItems, okData]);
    } else {
      setSelectedItems([]);
    }
  };

  useEffect(() => {
    // getAllDevice().then((res) => setDevice(res));
    setRender(false);
    getAllPackages().then((res) => {
      setAllPackages(res.packages);
    });
  }, [isModalOpen, render, showDeviceModal]);

  // #EDIT DEVICE
  const editDeviceItem = (DeviceItem) => {
    setEditDevicesItem(DeviceItem);
    setIsModalOpen(true);
  };

  // Delete Device
  const DeviceDeleteHandler = () => {
    deletePackage(DeviceIds).then((res) => {
      if (res.status === 200) {
        const NewObj = selectedItems?.filter((item) => {
          return !DeviceIds?.includes(item.id);
        });
        setSelectedItems(NewObj);
        setRender(true);
      }
    });
  };

  const addPlansHandler = () => {
    setIsModalOpen(true);
    setEditDevicesItem("");
  };
  return (
    <div className="position-relative plan-list-table">
      <div className="alert-header-buttons-group d-flex align-items-center justify-content-between mx-3">
        <h6>Plans</h6>
        <Button
          text="Delete"
          icon={<BiTrash className="Tabs-icons" />}
          oK={() => DeviceDeleteHandler()}
          buttonStyle={
            selectedItems?.length === 0
              ? "global-btn deleteDeactive me-3 btn-padding"
              : "global-btn green-btn me-3 btn-padding"
          }
        />
      </div>
      <table className="table mb-4">
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                onClick={(e) => handleSelectItem(e, currentItems, "allData")}
                value=""
                checked={
                  pageChnages
                    ? true
                    : currentItems?.length === 0
                    ? false
                    : currentItems?.length === selectedItems?.length
                    ? true
                    : false
                }
              />
            </th>
            <th>Sr No</th>
            <th>
              <span className="table-icons">
                <HiOutlineMail />
              </span>
              Title
            </th>{" "}
            <th>
              <span className="table-icons">
                <MdOutlineDescription />
              </span>{" "}
              Description
            </th>
            <th>
              <span className="table-icons">
                <RiMoneyDollarCircleLine />
              </span>
              Price
            </th>{" "}
            <th>
              <span className="table-icons">
                <BiBroadcast />{" "}
              </span>{" "}
              Subscription Type
            </th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {currentItems &&
            currentItems?.map((ele, index) => {
              let { description, price, subscription_type, title, status } =
                ele;
              // let readingarameter = JSON.parse(ele.device_type);
              return (
                <tr key={ele.id}>
                  <td>
                    <input
                      type="checkbox"
                      onClick={(e) => handleSelectItem(e, ele)}
                      value={ele}
                      checked={
                        pageChnages
                          ? false
                          : selectedItems.includes(ele)
                          ? true
                          : false
                      }
                    />
                  </td>
                  <td>{index + 1}</td>
                  <td
                    onClick={() => editDeviceItem(ele)}
                    className="linkable"
                    style={{ cursor: "pointer" }}
                  >
                    {title}
                  </td>
                  <td>
                    {" "}
                    <div
                      title={description}
                      style={{
                        width: "200px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {description}
                    </div>
                  </td>
                  <td>{price}</td>
                  <td>{subscription_type}</td>
                  {/* <td>{"asd"}</td>
                  <td>{"sjdf"}</td> */}
                  <td>
                    {" "}
                    {status !== "Deactive" ? "Verified" : "Not Verified"}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      {currentItems?.length === 0 && (
        <div className="d-flex flex-column justify-content-center align-items-center mb-3">
          <div style={{ fontSize: "50px", color: "#a9b6c2" }}>
            <BsInbox />
          </div>
          <p style={{ color: "#a9b6c2" }}>No Record Found.</p>
        </div>
      )}
      <div className="paginate">
        {currentItems?.length !== 0 && allPackages?.length >= 10 && (
          <ReactPaginate
            breakLabel="..."
            nextLabel={">"}
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel={"<"}
            renderOnZeroPageCount={null}
          />
        )}
      </div>
      <div className="global-button add-btn pb-4 text-end">
        <Button
          text="Add Plan"
          buttonStyle="global-btn primary-btn btn-padding"
          righIcon={<AiOutlinePlus />}
          oK={addPlansHandler}
        />
      </div>
      <CreatPackages
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        editDevicesItem={editDevicesItem}
      />
    </div>
  );
};

export default PackageTable;

import React, { useState, useEffect } from "react";
import { AiOutlineAppstoreAdd, AiOutlinePlus } from "react-icons/ai";
import { BiBarcode, BiDetail, BiTrash } from "react-icons/bi";
import ReactPaginate from "react-paginate";
import { getAllDevice, getRegisterdSensors } from "../../../ApisURl/ApisUrl";
import Button from "../../../Component/Button";
import { GrDocumentTime, GrStatusPlaceholder } from "react-icons/gr";

import { BsInbox, BsListCheck } from "react-icons/bs";
import { MdDeviceHub } from "react-icons/md";
import { RegisterSensor } from "./RegisteerSensorModal";

const AvailableSensors = ({ allTtnSensors, setAllTtnSensors, filterRows }) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [loaderEndDevices, setLoaderEndDevices] = useState(true);
  const [Device, setDevice] = useState([]);
  const [loader, setLoader] = useState(false);

  const [editDevicesItem, setEditDevicesItem] = useState();

  const [editEndDeviceItem, setEditEndDeviceItem] = useState({});
  const [showDeviceModal, setShowDeviceModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setId] = useState();
  const [DeviceName, setDeviceName] = useState();

  const [render, setRender] = useState(false);

  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + 10;

  const currentItems =
    filterRows?.serchText?.length !== 0
      ? filterRows?.filterRow?.slice(itemOffset, endOffset) || []
      : allTtnSensors?.slice(itemOffset, endOffset) || [];

  const pageCount =
    filterRows?.serchText?.length !== 0
      ? Math.ceil(filterRows?.filterRow?.length / 10)
      : Math.ceil(allTtnSensors?.length / 10);

  const handlePageClick = (event) => {
    const newOffset =
      filterRows?.serchText?.length !== 0
        ? (event.selected * 10) % filterRows?.filterRow?.length
        : (event.selected * 10) % allTtnSensors?.length;

    setItemOffset(newOffset);
  };

  const DeviceIds = selectedItems && selectedItems?.map((item) => item.id);

  const handleSelectItem = (e, okData, DataType) => {
    if (e.target.checked === true && DataType === "allData") {
      setSelectedItems(okData);
    } else if (selectedItems?.includes(okData)) {
      const NewObj = selectedItems?.filter((item) => {
        return item.id !== okData.id;
      });
      setSelectedItems(NewObj);
    } else if (!DeviceIds.id?.includes(okData.id) && DataType !== "allData") {
      setSelectedItems([...selectedItems, okData]);
    } else {
      setSelectedItems([]);
    }
  };

  useEffect(() => {
    // setLoaderEndDevices(true);
    getAllDevice().then((res) => {
      setDevice(res);
      // setLoaderEndDevices(false);
    });
    setRender(false);
  }, [isModalOpen, render, showDeviceModal]);

  // #EDIT DEVICE
  const editDeviceItem = (DeviceItem) => {
    setEditEndDeviceItem(DeviceItem);
    setIsModalOpen(true);
  };

  // Delete Device
  const DeviceDeleteHandler = () => {
    // if (DeviceIds?.length == "1") {
    //   deleteSensorAndDevice(DeviceIds && DeviceIds?.[0]).then((res) => {
    //     if (res) {
    //       const NewObj = selectedItems?.filter((item) => {
    //         return item.id !== DeviceIds?.[0];
    //       });
    //       setSelectedItems(NewObj);
    //     }
    //   });
    //   setRender(true);
    // }
  };

  useEffect(() => {
    setLoader(true);
    getRegisterdSensors().then((res) => {
      setAllTtnSensors(res?.devices);
      setLoader(false);
    });
    setRender(false);
  }, [isModalOpen, render]);

  const handleTimeEdit = (e) => {
    console.log("chaneg time insie function", e.target.innerText);
  };

  const registerHandler = () => {
    setEditEndDeviceItem();
    setIsModalOpen(true);
  };

  return (
    <div className="position-relative">
      <div
        className="alert-header-buttons-group "
        style={{ position: "absolute", top: "-57px", right: "20px" }}
      >
        {/* <Button
          text="Delete"
          icon={<BiTrash className="Tabs-icons" />}
          oK={() => DeviceDeleteHandler()}
          buttonStyle={
            selectedItems?.length === 0
              ? "global-btn deleteDeactive me-3 btn-padding"
              : "global-btn green-btn me-3 btn-padding"
          }
        /> */}
      </div>
      <table className="table position-relative">
        <thead>
          <tr>
            {/* <th>
              <input
                type="checkbox"
                onClick={(e) => handleSelectItem(e, currentItems, "allData")}
                value=""
                checked={
                  currentItems?.length === selectedItems?.length ? true : false
                }
              />
            </th> */}
            <th>
              <span className="table-icons">
                <BiBarcode />
              </span>
              Serial Number
            </th>
            <th>
              <span className="table-icons">
                <AiOutlineAppstoreAdd />
              </span>
              Join Id
            </th>{" "}
            <th>
              <span className="table-icons">
                <BiDetail />
              </span>{" "}
              Device Id
            </th>
            <th>
              <span className="table-icons">
                <BsListCheck />
              </span>{" "}
              Reading
            </th>
            <th>
              <span className="table-icons">
                <GrDocumentTime />
              </span>{" "}
              Data Interval (sec.)
            </th>
            <th>
              <span className="table-icons">
                <MdDeviceHub />
              </span>
              Device Type
            </th>
            <th>

              Communication Type
            </th>
            <th className="ps-3">
              <span className="table-icons">
                <GrStatusPlaceholder />
              </span>
              Status
            </th>
          </tr>
        </thead>
        <tbody>
          {currentItems?.map((ele, index) => {
            let readingarameter;
            try {
              readingarameter = JSON.parse(ele?.readings);
            } catch (error) { }

            let isItArray = Array.isArray(ele?.devicereadings)
            return (
              <tr key={ele.id}>
                {/* <td>
                  <input
                    type="checkbox"
                    onClick={(e) => handleSelectItem(e, ele)}
                    value={ele}
                    checked={selectedItems.includes(ele) ? true : false}
                  />
                </td> */}
                <td
                  onClick={() => editDeviceItem(ele)}
                  className="linkable"
                  style={{ cursor: "pointer" }}
                >
                  {ele.ids?.device_id}
                </td>
                <td>{ele.ids?.join_eui}</td>
                <td>{ele.ids?.dev_eui}</td>
                <td>
                  {isItArray ? ele?.devicereadings?.map((reading, index) => (
                    <span key={index}>
                      {reading?.reading?.reading}
                      {index < ele.devicereadings.length - 1 && ", "}
                    </span>
                  )) : ele?.devicereadings}
                </td>
                <td style={{ textAlign: "start" }}>
                  <div
                    contentEditable={true}
                    onBlur={handleTimeEdit}
                    suppressContentEditableWarning={true}
                    style={{ width: "90px", padding: "0 10px" }}
                  >
                    {ele.response_time}
                  </div>
                </td>
                <td>{ele.type}</td>
                <td>{ele.communication_type}</td>
                <td style={{ textAlign: "start" }}>{ele.status}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {currentItems?.length === 0 && (
        <>
          {loader ? (
            <div className="d-flex flex-column justify-content-center align-items-center mb-3">
              <p style={{ color: "#a9b6c2", display: "flex" }}>
                <span className="loader_2"></span>
              </p>
            </div>
          ) : (
            <div className="d-flex flex-column justify-content-center align-items-center mb-3">
              <div style={{ fontSize: "50px", color: "#a9b6c2" }}>
                <BsInbox />
              </div>
              <p style={{ color: "#a9b6c2" }}>No Record Found.</p>
            </div>
          )}
        </>
      )}

      <div className="paginate">
        {currentItems?.length !== 0 && allTtnSensors?.length >= 10 && (
          <ReactPaginate
            breakLabel="..."
            nextLabel={">"}
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel={"<"}
            renderOnZeroPageCount={null}
          />
        )}
      </div>
      <div className="global-button add-btn pb-4 text-end">
        <Button
          text="Register end device"
          buttonStyle="global-btn primary-btn btn-padding"
          righIcon={<AiOutlinePlus />}
          oK={registerHandler}
        />
      </div>
      <RegisterSensor
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        editEndDeviceItem={editEndDeviceItem}
        setEditEndDeviceItem={setEditEndDeviceItem}
        render={render}
        setRender={setRender}
      />
    </div>
  );
};

export default AvailableSensors;

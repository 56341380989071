import { DatePicker } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { BiCalendar, BiRupee } from "react-icons/bi";
import { BsInbox } from "react-icons/bs";
import { IoArrowBack } from "react-icons/io5";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  assignNewSubscription,
  getAllPackages,
} from "../../../ApisURl/ApisUrl";

const Currnetpackage = ({}) => {
  const [selectedDates, setSelectedDates] = useState({});

  const [allPackages, setAllPackages] = useState([]);
  const [selectStartDate, setSelectStartDate] = useState();
  const [currentPackage, setCurrentPackage] = useState({});

  const userRecord = useSelector((state) => state.userPlan.userCurrentPackage);
  const navigate = useNavigate();
  useEffect(() => {
    getAllPackages().then((res) => setAllPackages(res.packages));
  }, []);

 

  const sendDateHanlder = (date, eleId) => {
    setSelectedDates((prevSelectedDates) => ({
      ...prevSelectedDates,
      [eleId]: date.isValid() ? date : null,
    }));
  };

  const calculateEndDate = (startDate, subscriptionType) => {
    let futureDate;

    if (subscriptionType === "Free") {
      futureDate = moment(startDate).add(1, "months");
    } else if (subscriptionType === "Monthly") {
      futureDate = moment(startDate).add(1, "months");
    } else if (subscriptionType === "Halfyearly") {
      futureDate = moment(startDate).add(6, "months");
    } else if (subscriptionType === "Yearly") {
      futureDate = moment(startDate).add(12, "months");
    }
    return moment(futureDate).format("YYYY-MM-DD");
  };

  const assignPlanHandler = (data) => {
    let id = data.id + "";
    const selectedDate = selectedDates[data.id];

    const startDate = moment(selectedDate?.$d).format("YYYY-MM-DD");
    const endDate = calculateEndDate(startDate, data.subscription_type);
    

    let body = {
      start_date: startDate,
      end_date: endDate,
      farmerId:
        userRecord.subscription === null
          ? userRecord.farmer.id
          : userRecord?.subscription?.farmerId,
      packageId: data.id,
    };

    assignNewSubscription(body).then((res) => {
      setCurrentPackage(res.data);
      setSelectedDates((prevSelectedDates) => {
        const updatedSelectedDates = { ...prevSelectedDates };
        delete updatedSelectedDates[data.id];
        return updatedSelectedDates;
      });
    });
  };

  const onShowShubscriptionStatus = (subscriptionStatus) => {
    if (subscriptionStatus === "Expired") {
      return (
        <span
          className=" fw-normal current-plan-status-btn expired-one"
          style={{ fontSize: "12px", margin: "0 0 -5px 3px" }}
        >
          {subscriptionStatus}
        </span>
      );
    } else if (subscriptionStatus === "Active") {
      return (
        <span
          className=" fw-normal current-plan-status-btn active-one"
          style={{ fontSize: "12px", margin: "0 0 -5px 3px" }}
        >
          {subscriptionStatus}
        </span>
      );
    }
  };

  return (
    <div className="current-plan ">
      <div className="setting-section-header d-flex justify-content-between align-items-center">
        <h1 className="title mt-2 d-flex align-items-center ">
          <span
            className="go-back  me-2"
            title=" Back "
            onClick={() => navigate("/Farmers")}
          >
            <IoArrowBack />
          </span>
          {`${userRecord?.farmer?.first_name}'s plan`}
          {/* <span className=" sub-title ">Product Management</span> */}
        </h1>
      </div>
      {(userRecord.subscription || currentPackage?.current_subscription) && (
        <div className="packageDevice   px-4 pb-4 rounded currentPackage-style  ">
          <p className="fs-6 pt-3" style={{ margin: "0 0 10px 0" }}>
            Current Package
          </p>
          <span className="fs-4 d-flex align-items-center fw-bold mb-1">
            {currentPackage?.current_subscription
              ? currentPackage?.current_subscription?.title
              : userRecord?.subscription?.title}

            {onShowShubscriptionStatus(
              currentPackage?.current_subscription
                ? currentPackage?.status
                : userRecord?.subscription?.status
            )}
          </span>
          <div>
            <div className="price d-flex ">
              <div className="price-iconc fw-lighter me-2">
                <BiCalendar style={{ fontWeight: "500 !important" }} />
                <span className="fw-bold ms-1">From :</span>
                <span className="text-secondary">
                  &nbsp;
                  {currentPackage?.current_subscription
                    ? moment(currentPackage?.response?.start_date).format("ll")
                    : moment(userRecord?.subscription?.start_date).format("ll")}
                </span>
              </div>

              <div className="price-iconc fw-lighter me-2">
                - &nbsp;
                <BiCalendar style={{ fontWeight: "500 !important" }} />
                <span className="fw-bold ms-1">To :</span>
                <span className="text-secondary">
                  &nbsp;
                  {currentPackage?.current_subscription
                    ? moment(currentPackage?.response?.end_date).format("ll")
                    : moment(userRecord?.subscription?.end_date).format("ll")}
                </span>
              </div>
              <div className="price-iconc fw-lighter me-2">
                <BiRupee style={{ fontWeight: "500 !important" }} />
                <span className="fw-bold ">Price</span> |{" "}
                <span className="text-secondary">
                  {currentPackage?.current_subscription
                    ? currentPackage?.current_subscription?.price
                    : userRecord?.subscription?.price}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="portal-packages bg-white px-4 py-4 my-3">
        <p className="fs-6" style={{ margin: " 0  0 10px 0" }}>
          Assign Next Package
        </p>

        <table className="table mb-4">
          <thead>
            <tr>
              <th>Sr No</th>
              <th>Title </th>
              <th>Description</th> <th>Price</th>
              <th>Subscription Type</th>
              <th>Select Start Date</th>
              <th>Manage</th>
            </tr>
          </thead>
          <tbody>
            {/* {allPackages?.length !== 0 ? ( */}
            {allPackages?.map((ele, index) => {
              let { description, price, subscription_type, title } = ele;
              return (
                <tr
                  key={ele.id}
                  className={
                    title === userRecord?.subscription?.title
                      ? "hasPackage"
                      : ""
                  }
                >
                  <td>{index + 1}</td>
                  <td>{title}</td>
                  <td>
                    <div
                      title={description}
                      style={{
                        width: "150px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {description}
                    </div>
                  </td>
                  <td>{price}</td>
                  <td>{subscription_type}</td>
                  <td>
                    {" "}
                    <DatePicker
                      value={selectedDates[ele.id]}
                      clearIcon={null}
                      onChange={(date) => {
                        sendDateHanlder(date, ele.id);
                      }}
                    />
                  </td>

                  <td>
                    <button
                      className={
                        !selectedDates.hasOwnProperty(ele.id)
                          ? " assign-btn  deActive-assign-btn"
                          : "assign-btn  bg-assign-btn"
                      }
                      onClick={() => {
                        if (selectedDates.hasOwnProperty(ele.id)) {
                          assignPlanHandler(ele);
                        }
                      }}
                    >
                      Assign
                    </button>
                  </td>
                </tr>
              );
            })}
            {/* ) : (
              <tr>
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <div style={{ fontSize: "50px", color: "#a9b6c2" }}>
                    <BsInbox />
                  </div>
                  <p style={{ color: "#a9b6c2" }}>No Record Found.</p>
                </div>
              </tr>
            )} */}
          </tbody>
        </table>
        {allPackages?.length === 0 && (
          <div className="d-flex flex-column justify-content-center align-items-center">
            <div style={{ fontSize: "50px", color: "#a9b6c2" }}>
              <BsInbox />
            </div>
            <p style={{ color: "#a9b6c2" }}>No Record Found.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Currnetpackage;

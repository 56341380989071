import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const BaseUrl = process.env.REACT_APP_AGROIOT_BASE_URL;

export const Register = createAsyncThunk(
  "Register/Register",
  async (params) => {
    try {
      let Response = await axios.post(`${BaseUrl}/users`, params, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if ((Response.status = "200")) {
        return toast.success("Registration completed successfully");
      } else {
        toast.error("Registration failed");
      }
    } catch (error) {
      return toast.error("Registration failed");
    }
  }
);

export const Forget = createAsyncThunk("forget/Forget", async (params) => {
  try {
    let Response = await axios.post(
      `${BaseUrl}/users/forgot-password`,
      params,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if ((Response.status = "200")) {
      return toast.success(Response.data.message);
    } else {
      toast.error("Please fill the registerd email");
    }
  } catch (error) {
    return toast.error("Please fill the registerd email");
  }
});

const userSlice = createSlice({
  name: "user",
  initialState: {
    user: "",
    error: "",
    status: "Idle",
  },
  extraReducers: {
    [Register.pending]: (state) => {
      state.status = "pending";
    },
    [Register.fulfilled]: (state) => {
      state.status = "successfull";
    },
    [Register.pending]: (state) => {
      state.status = "pending";
    },

    [Forget.pending]: (state) => {
      state.status = "pending";
    },
    [Forget.fulfilled]: (state) => {
      state.status = "successfull";
    },
    [Forget.pending]: (state) => {
      state.status = "pending";
    },
  },
});

export default userSlice.reducer;

import React, { useEffect, useState } from "react";
import { BiSelectMultiple, BiTask } from "react-icons/bi";
import Searchbar from "../../../Component/Serchbar/Serchbar";
import { Tab, Tabs } from "react-bootstrap";
import Button from "../../../Component/Button";

import { io } from "socket.io-client";
import TemplateTable from "./TemplateTable";
// import BreadCumbs from "../../Component/BreadCumbs/Index";

// this is alert main page
const AlertTemplateIndex = () => {
  const [tab, setTab] = useState("0");
  const [allTemplates, setAllTemplates] = useState([]);
  const [allAlertsitem, setAllAlertsItem] = useState([]);
  const [filterRows, setFilterRows] = useState({
    filterRow: [],
    serchText: "",
  });

  function performSearch(regex) {
    if (tab === "1") {
      return (
        allAlertsitem && allAlertsitem?.filter((item) => regex.test(item?.name))
      );
    } else if (tab === "0") {
      return (
        allTemplates &&
        allTemplates?.filter((item) => regex.test(item?.template?.name))
      );
    }
  }

  function handleSearch(newSearchTerm) {
    const regex = new RegExp(newSearchTerm, "i");
    const result = performSearch(regex);
    setFilterRows({
      ...filterRows,
      filterRow: result,
      serchText: newSearchTerm,
    });
  }

  // Filter and getsingle unreadfarm

  let SingleFarmArray = [];
  const filterDaata = allAlertsitem?.filter((item) => {
    if (item.status === "unread") {
      return item;
    }
  });

  for (let i = 0; i < filterDaata?.length; i++) {
    let Farm = SingleFarmArray.map((i) => i.farm_name);
    if (!Farm.includes(filterDaata[i].farm_name)) {
      SingleFarmArray.push(filterDaata[i]);
    }
  }

  return (
    <div className="alert-section">
      {/* header section */}
      <div className="alert-section-header d-flex justify-content-between align-items-center">
        <h1 className="title">Alerts</h1>
        <Searchbar onSearch={handleSearch} tab={tab} />
      </div>

      {/* body sectioon */}
      <div className="alert-section-body">
        <div className="alert-body-table">
          <div className="alert-body-table-header ">
            <div className="tab-panel position-relative">
              <Tabs
                defaultActiveKey={tab}
                id="uncontrolled-tab-example"
                className="mb-3"
                onSelect={(k) => {
                  setTab(k);
                  setFilterRows({
                    filterRow: [],
                    serchText: "",
                  });
                }}
              >
                <Tab
                  eventKey="0"
                  title={
                    <div style={{ position: "relative" }}>
                      <span className="Tabs-icons">
                        <BiTask />
                      </span>
                      Alert Template
                    </div>
                  }
                >
                  <TemplateTable
                    filterRows={filterRows}
                    allTemplates={allTemplates}
                    setAllTemplates={setAllTemplates}
                  />
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertTemplateIndex;

import "./Assets/CSS/global.css";
import "./Assets/CSS/app.css";
import "./Assets/CSS/SuperAdmin.css";
import MainRoutingFile from "./Navigation/index";

function App() {
  return (
    <div className="App">
      <MainRoutingFile />
    </div>
  );
}

export default App;

import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = () => {
  const { AuthUser } = useSelector((state) => state);
  const isAuthenticated = Object.keys(AuthUser?.user).length !== 0;


  return isAuthenticated ? <Outlet /> : <Navigate to="/" />;
};

//📔 Outlet Means its parent route element that renders their nested Route or children route

export default PrivateRoute;

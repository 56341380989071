import { Select } from "antd";
import { ErrorMessage, Field, Formik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { BiMinusCircle } from "react-icons/bi";
import { getAllSensorsReadings } from "../../../../ApisURl/ApisUrl";

const ParameterRule = ({
  values,
  setFieldValue,
  touched,
  errors,
  editRecord,
  editable,
}) => {
  const [readings, setReadings] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const selectedParameters = values.template_para.map((item) => item.parameter);

  const availableOptions = readings?.filter(
    (option) => !selectedParameters.includes(option?.reading)
  );

  useEffect(() => {
    getAllSensorsReadings().then((res) => setReadings(res?.readings));
  }, []);

  const addedOnMoreRow = () => {
    if (availableOptions?.length > 0) {
      const templates_parameter = [...values.template_para];
      templates_parameter.push({
        parameter: "",
        condition: "",
        value: "",
        templateId: editRecord ? editRecord?.template?.id : "",
      });
      setFieldValue("template_para", templates_parameter);
    }
  };

  const deleteOneRow = (index) => {
    if (values.template_para?.length > 1) {
      const updatedTemplatePara = [...values.template_para];
      updatedTemplatePara?.splice(index, 1);
      setFieldValue("template_para", updatedTemplatePara);
    }
  };



  const handleMouseEnter = useMemo(
    () => (index) => {
      setHoveredIndex(index);
    },
    []
  );

  const handleMouseLeave = useMemo(
    () => () => {
      setHoveredIndex(null);
    },
    []
  );

  // filtering the reading option

  return (
    <div className="py-3" style={{ borderBottom: "1px solid #D9D4D4" }}>
      <label htmlFor="Rule" className="d-flex justify-contetn-between ">
        <span
          className="ms-3 pt-5"
          style={{
            width: "60px",
            color: "black",
            fontWeight: "700",
          }}
        >
          Rule{" "}
        </span>
        <div
          className=" border py-2 "
          style={{ width: "550px", borderRadius: "15px" }}
        >
          {values.template_para.map((item, index) => {
            const isHovered = hoveredIndex === index;
            return (
              <>
                <div
                  key={index}
                  className="d-flex align-items-center "
                  style={{ width: "95%" }}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                >
                  <span className="mx-2 ">When</span>
                  <Field name={`template_para[${index}].parameter`}>
                    {({ field, form }) => (
                      <>
                        <Select
                          name={`parameter`}
                          disabled={editRecord && !editable}
                          value={field.value}
                          style={{ width: "450px" }}
                          status={
                            form?.touched?.template_para?.[index]?.parameter &&
                              form?.errors?.parameter?.[index]?.parameter
                              ? "error"
                              : ""
                          }
                          onChange={(e) => {
                            setFieldValue(field.name, e);
                          }}
                          placeholder="select parameter"
                        >
                          {availableOptions?.map((option, idx) => {
                            return (
                              <option key={option?.id} value={option?.reading}>
                                {option?.reading}
                              </option>
                            );
                          })}
                        </Select>
                      </>
                    )}
                  </Field>
                  {/* <ErrorMessage name="parameter" /> */}
                  <span className="mx-2">is</span>
                  <Field name={`template_para[${index}].condition`}>
                    {({ field, form }) => (
                      <Select
                        style={{ width: "400px" }}
                        value={field.value}
                        name="condition "
                        disabled={editRecord && !editable}
                        // value={values.condition}
                        status={
                          form?.touched?.template_para?.[index]?.condition &&
                            form?.errors?.condition?.[index]?.condition
                            ? "error"
                            : ""
                        }
                        onChange={(e) => {
                          setFieldValue(field.name, e);
                        }}
                        placeholder="Select condition"
                      >
                        <option value="Less than">Less than</option>
                        <option value="Greater than">Greater than </option>
                      </Select>
                    )}
                  </Field>
                  <span className="ms-2"></span>
                  <Field
                    type="number"
                    name={`template_para[${index}].value`}
                    disabled={editRecord && !editable}
                    autoComplete="off"
                    value={item?.value}
                    placeholder="Count"
                    onChange={(e) => {
                      setFieldValue(
                        `template_para[${index}].value`,
                        e.target.value
                      );
                    }}
                    className={`form-control FormikInput ${touched?.template_para?.[index]?.value &&
                      errors?.value?.[index]?.value
                      ? "invalid-field"
                      : ""
                      }`}
                    style={{ width: "80px" }}
                    min={0}
                  />

                  {/* ===  Here is Changing the Units of number ===  */}
                  {values?.template_para[index]?.parameter === "Temperature" ? (
                    <span className="ms-2">
                      <sup>o</sup>c
                    </span>
                  ) : values?.template_para[index]?.parameter === "Humidity" ? (
                    <span className="ms-2">%</span>
                  ) : values?.template_para[index]?.parameter ===
                    "Sun Light" ? (
                    <span className="ms-2">lux</span>
                  ) : (
                    ""
                  )}

                  {isHovered && (
                    <div
                      style={{
                        position: "relative",
                        backgroundColor: "transparent",
                      }}
                    >
                      {editRecord && !editable ? (
                        ""
                      ) : (
                        <>
                          {values?.template_para?.length > 1 && (
                            <span
                              onClick={() => deleteOneRow(index)}
                              className="ms-2 remove-btn-row position-absolute"
                              style={{
                                backdropFilter: isHovered
                                  ? "blur(2px)"
                                  : "none",
                                backgroundColor: isHovered
                                  ? "#ffffff"
                                  : "rgba(255, 255, 255, 0.8)",
                              }}
                            >
                              <BiMinusCircle
                                className="text-danger "
                                style={{ fontSize: "20px" }}
                              />
                            </span>
                          )}
                        </>
                      )}

                      {/* Add other buttons */}
                    </div>
                  )}
                </div>
                {touched?.template_para?.[index]?.value &&
                  errors?.value?.[index]?.value && (
                    <div className="text-danger ms-5 ps-2 Errormessage">
                      {errors?.value?.[index]?.value}
                    </div>
                  )}
              </>
            );
          })}
          {editRecord && !editable ? (
            ""
          ) : (
            <div className="d-flex w-100 justify-content-end me-5 pt-2">
              {availableOptions?.length > 0 && (
                <button
                  className="    "
                  style={{
                    border: "1px solid green",
                    color: "green",
                    padding: "2px 10px ",
                    background: "white",
                    borderRadius: "6px",
                    fontSize: "10px",
                    marginRight: "30px",
                  }}
                  type="button"
                  onClick={addedOnMoreRow}
                >
                  Add Row
                </button>
              )}
            </div>
          )}
        </div>
      </label>
      <ErrorMessage
        name="value"
        component="div"
        className="text-danger Errormessage"
        style={{
          marginLeft: "125px",
          paddingTop: "2px",
        }}
      />
    </div>
  );
};

export default ParameterRule;

import { createSlice } from "@reduxjs/toolkit";

const selectSlice = createSlice({
  name: "selectTableValue",
  initialState: {
    selectedItems: [],
    selectedItemCount: 0,
    status: "",
  },
  reducers: {
    SelectRecord: (state, action) => {
      state.selectedItems = action.payload;
      state.selectedItemCount = action.payload.length;
      state.status = "successfull";
    },
    RemoveRecord: (state, action) => {
      state.selectedItems.filter((item) => {
        return item.id !== action.payload.id;
      });
    },
  },
});

export default selectSlice.reducer;
export const { SelectRecord, RemoveRecord } = selectSlice.actions;

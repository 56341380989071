import moment from "moment";
import React, { useState, useEffect } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import {
  BiChat,
  BiDroplet,
  BiListCheck,
  BiReceipt,
  BiTrash,
} from "react-icons/bi";
import { BsArrowRepeat, BsInbox } from "react-icons/bs";
import { FaRegCalendarAlt, FaThermometerFull } from "react-icons/fa";
import { FiAlertTriangle } from "react-icons/fi";
import ReactPaginate from "react-paginate";
import {
  deleteMultiTemplates,
  deleteTemlates,
  getTemplates,
} from "../../../ApisURl/ApisUrl";
import Button from "../../../Component/Button";
import AddAlertTemplateSuper from "./AddSuperAlertTemplate/AddAlertTemplateSuper";

const TemplateTable = ({ filterRows, allTemplates, setAllTemplates }) => {
  const [editRecord, setEditRecord] = useState();
  const [expanded, setExpanded] = useState(false);
  const [expandedItem, setExpandedItem] = useState();

  const [selectedItems, setSelectedItems] = useState([]);
  const [pageChnages, setPageChnage] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + 5;

  const currentItems =
    filterRows?.serchText?.length !== 0
      ? filterRows?.filterRow?.slice(itemOffset, endOffset) || []
      : allTemplates?.slice(itemOffset, endOffset) || [];

  const pageCount =
    filterRows?.serchText?.length !== 0
      ? Math.ceil(filterRows?.filterRow?.length / 5)
      : Math.ceil(allTemplates?.length / 5);

  const handlePageClick = (event) => {
    const newOffset =
      filterRows?.serchText?.length !== 0
        ? (event.selected * 5) % filterRows?.filterRow?.length
        : (event.selected * 5) % allTemplates?.length;

    setItemOffset(newOffset);
    setPageChnage(true);
  };

  const handleSelectItem = (e, okData, DataType) => {
    if (e.target.checked === true && DataType === "allData") {
      setSelectedItems([...selectedItems, okData]);
      setSelectedItems(okData);
      setPageChnage(false);
    } else if (selectedItems?.includes(okData)) {
      const NewObj = selectedItems?.filter((item) => {
        return item !== okData;
      });
      setSelectedItems(NewObj);
    } else if (!selectedItems?.includes(okData) && DataType !== "allData") {
      setSelectedItems([...selectedItems, okData]);
    } else {
      setSelectedItems([]);
    }
  };

  useEffect(() => {
    getTemplates().then((res) => setAllTemplates(res.templates));
  }, [isModalOpen]);

  const oneditHandler = (pickAlertItem) => {
    setIsModalOpen(true);
    setEditRecord(pickAlertItem);
  };
  const toggleExpand = (id) => {
    setExpanded(!expanded);
    setExpandedItem(id);
  };

  // delete template
  const onhandleDeleteHandler = () => {
    if (currentItems?.length !== 0 && selectedItems) {
      if (selectedItems?.length === 1) {
        deleteTemlates(selectedItems[0]?.template.id).then((res) => {
          if (res.status === 200) {
            const newData = selectedItems.filter((item) => {
              return item.template.id !== selectedItems[0]?.template.id;
            });
            setSelectedItems(newData);
            // re-set main state
            const MainState = allTemplates.filter((item) => {
              return item.template.id !== selectedItems[0]?.template.id;
            });
            setAllTemplates(MainState);
          }
        });
      } else {
        const Ids = selectedItems?.map((items) => items.template.id);

        deleteMultiTemplates(Ids).then((res) => {
          if (res.status === 200) {
            const newData = selectedItems.filter((item) => {
              return !Ids.includes(item.template.id);
            });
            setSelectedItems(newData);
            const MainState = allTemplates.filter((item) => {
              return !Ids.includes(item.template.id);
            });
            setAllTemplates(MainState);
          }
        });
      }
    }
  };
  return (
    <div className="position-relative">
      <div
        className="alert-header-buttons-group"
        style={{ position: "absolute", top: "-56px", right: "20px" }}
      >
        {currentItems?.length !== 0 && (
          <Button
            text="Delete"
            icon={<BiTrash className="Tabs-icons" />}
            oK={() => onhandleDeleteHandler()}
            buttonStyle={
              selectedItems?.length === 0
                ? "global-btn deleteDeactive me-3 btn-padding"
                : "global-btn green-btn me-3 btn-padding"
            }
          />
        )}
      </div>
      <table className="table mb-4">
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                onClick={(e) => handleSelectItem(e, currentItems, "allData")}
                value=""
                checked={
                  pageChnages
                    ? false
                    : currentItems?.length === 0
                      ? false
                      : currentItems?.length === selectedItems?.length
                        ? true
                        : false
                }
              />
            </th>
            <th>
              <span className="table-icons">
                <FiAlertTriangle />{" "}
              </span>{" "}
              Name
            </th>
            <th>
              <span className="table-icons">
                <BiReceipt />{" "}
              </span>{" "}
              Message
            </th>{" "}
            <th>
              <span className="table-icons">
                <BiChat />{" "}
              </span>{" "}
              Inform Via
            </th>
            <th>
              <span className="table-icons">
                <BsArrowRepeat />{" "}
              </span>{" "}
              Repeat
            </th>
            <th>
              <span className="table-icons">
                <FaRegCalendarAlt />{" "}
              </span>{" "}
              Create On
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {currentItems?.length !== 0 &&
            currentItems?.map((ele, index) => (
              <tr key={index}>
                <td>
                  <input
                    type="checkbox"
                    onClick={(e) => handleSelectItem(e, ele)}
                    value={ele}
                    name={ele}
                    checked={selectedItems?.includes(ele) ? true : false}
                  />
                </td>
                <td
                  onClick={() => oneditHandler(ele)}
                  style={{ cursor: "pointer", width: "10%" }}
                  className="linkable"
                >
                  <div className="mesages d-flex align-items-center justify-content-start">
                    {ele?.template?.name}
                  </div>
                </td>
                <td style={{ width: "50%" }}>
                  <p
                    className="user-select-none"
                    title={ele?.template?.message}
                    onClick={() => toggleExpand(ele?.template?.id)}
                    style={{ cursor: "pointer" }}
                  >
                    {expanded && ele?.template?.id === expandedItem ? (
                      <div> {ele?.template?.message}</div>
                    ) : (
                      <div className="">
                        {ele?.template?.message?.length >= 72
                          ? `${ele?.template?.message?.slice(0, 72)}...`
                          : ele?.template?.message}{" "}
                      </div>
                    )}
                  </p>
                </td>

                <td style={{ width: "150px" }}>{ele?.template?.inform_via}</td>
                <td style={{ width: "120px" }}>
                  <span className="ms-2">
                    {ele.template?.repeat_after === "never"
                      ? "Never"
                      : ele?.template?.repeat_after}
                  </span>
                </td>
                <td>
                  {" "}
                  <div title={moment(ele?.template?.createdAt).format("LLL")}>
                    {moment(ele?.template?.createdAt).format("LLL")}
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {currentItems?.length === 0 && (
        <div className="d-flex flex-column justify-content-center align-items-center mb-3">
          <div style={{ fontSize: "50px", color: "#a9b6c2" }}>
            <BsInbox />
          </div>
          <p style={{ color: "#a9b6c2" }}>No Record Found</p>
        </div>
      )}

      <div className="paginate">
        {currentItems?.length !== 0 && allTemplates?.length >= 10 && (
          <ReactPaginate
            breakLabel="..."
            nextLabel={">"}
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel={"<"}
            renderOnZeroPageCount={null}
          />
        )}
      </div>

      <div className="global-button add-btn pb-4 text-end">
        <Button
          text="Add Alert"
          buttonStyle="global-btn primary-btn btn-padding"
          righIcon={<AiOutlinePlus />}
          oK={() => {
            setEditRecord("");
            setIsModalOpen(true);
          }}
        />
      </div>
      <AddAlertTemplateSuper
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        editRecord={editRecord}
      />
    </div>
  );
};

export default TemplateTable;
